import { createLazyComponent, LazyImport, LazyModule } from 'owa-bundling';
import { Loading, NetworkError } from 'owa-interstitial';

export { DEV_PANEL, WORK_PLAN, PEOPLE } from './HomeRoutes';

export { default as useHybridspaceHomeLinks } from './useHybridspaceHomeLinks';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HybridspaceHomeRoute" */ './lazyIndex'),
    { name: 'HybridspaceHomeRoute' }
);
export const PlacesHomeRouteLayout = createLazyComponent(
    lazyModule,
    m => m.PlacesHomeRouteLayout,
    Loading,
    NetworkError
);

export const lazyWorkplanModule = new LazyImport(lazyModule, m => m);

export const WorkplanScreen = createLazyComponent(
    lazyModule,
    m => m.WorkplanScreen,
    Loading,
    NetworkError
);
