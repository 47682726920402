import { placesFwkBootStartAction } from 'hybridspace-performance-datapoints';
import { isPlacesOfflineSyncEnabled } from 'hybridspace-persistent-store/lib/isPlacesOfflineSyncEnabled';
import { initializePlacesFinderStore } from 'hybridspace-places-finder-store';
import { logAppBootStep } from 'hybridspace-telemetry/lib/logAppBootStep';
import getGlobalSettingsAccountMailboxInfo from 'owa-account-source-list-store/lib/selectors/getGlobalSettingsAccountMailboxInfo';
import { logGreyError } from 'owa-analytics';
import { registerLPCInitializeOrchestrator } from 'owa-lpc-initialize';
import { lazyLoadPlacesAnalyticsCoreOperations } from 'places-analytics-app-store';
import { writePlaceCacheToApollo } from 'places-service';
import loadPlacesSettings from 'places-settings/lib/loadPlacesSettings';
import { lazyCleanUpPlacesOfflineSync } from 'places-signout';
import {
    getModuleState,
    initializeWeekPickerViewState,
    setModuleStateSafe,
} from 'places-user-session-stores';
import { handleMetaOSDeepLinkRedirection } from './utils/handleMetaOSDeepLinkRedirection';

import type { SessionData } from 'owa-service/lib/types/SessionData';

export async function initializeState(sessionData?: SessionData | undefined) {
    logAppBootStep('InitializeState');
    placesFwkBootStartAction(sessionData?.isOffline ? 'OfflineSessionData' : 'OnlineSessionData');

    const isSyncEnabled = isPlacesOfflineSyncEnabled();
    if (!isSyncEnabled) {
        const controller = new AbortController();
        let timer: NodeJS.Timeout | undefined;
        await Promise.race([
            // lazy load the clean up function and execute it
            lazyCleanUpPlacesOfflineSync
                .importAndExecute(controller.signal)
                .catch(err => {
                    logGreyError('PlacesOfflineSyncCleanUpError', err);
                })
                .finally(() => {
                    clearTimeout(timer);
                }),
            // in case the lazy load takes too long,
            // 200ms timeout to abort the clean up function to avoid blocking the boot process
            new Promise<void>(resolve => {
                timer = setTimeout(() => {
                    controller.abort();
                    resolve();
                }, 200);
            }),
        ]);
    }

    loadPlacesSettings(
        getGlobalSettingsAccountMailboxInfo(),
        true /** shouldUseMainThread to send via main thread to avoid worker thread set up cost */
    );

    // This function pre-loads necessary API calls (fire and forget) to optimize load time for PlacesAnalytics.
    // It takes the current page route into account to determine which APIs to preload.
    // For the Analytics homepage:
    //    - Preload core analytics APIs to optimize load time.
    // For other pages (e.g., Employee view):
    //    - Preload only the UserFeatureAccess (RBAC) API initially.
    //    - If RBAC confirms Admin access, proceed to load the remaining analytics core APIs.
    lazyLoadPlacesAnalyticsCoreOperations.importAndExecute();

    setModuleStateSafe('boot');
    setTimeout(() => {
        // TODO: remove this when we improve appView FSM for error handling and other modules etc.
        // forces us to remove loading screen if we don't complete in time to maintain similar behavior as of developing.
        if (getModuleState() !== 'complete') {
            setModuleStateSafe('complete');
        }
    }, 5000 /** 5s */);

    registerLPCInitializeOrchestrator();

    // We would have timezone initialized by now
    // so initializing date here would ensure application layer
    // to use user's timezone instead of UTC
    initializeWeekPickerViewState();
    initializePlacesFinderStore();

    writePlaceCacheToApollo();

    // Handles MetaOS deep link redirection
    await handleMetaOSDeepLinkRedirection();
    return null;
}
