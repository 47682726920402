import type { ShortenEventScopeType } from './ShortenEventScopeType';
import type RoamingTimezone from './RoamingTimezone';
import type { GlobalCalendarLanguage, GlobalCalendarType } from 'owa-datetime';
import type { CalendarFiltersTypes } from './CalendarEventsFilters';

// Calendar cloud setting value types as strings
export enum CalendarCloudSettingValueTypeString {
    Bool = 'Bool',
    Int = 'Int',
    String = 'String',
    Json = 'JSON',
}

// Calendar cloud setting scope types
export enum CalendarCloudSettingScopeType {
    Global = 'global',
    Account = 'account',
}

// Calendar cloud setting value types
export type CalendarCloudSettingValueType =
    | boolean
    | number
    | string
    | ShortenEventScopeType
    | RoamingTimezone[]
    | GlobalCalendarLanguage
    | GlobalCalendarType
    | Map<CalendarFiltersTypes, string[]>;
