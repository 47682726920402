import { startAppBootStep } from 'hybridspace-telemetry/lib/logAppBootStep';
import { logGreyError } from 'owa-analytics';
import { addDays, getISOString, isAfter, now, userDate } from 'owa-datetime';
import { isFeatureEnabled } from 'owa-feature-flags';
import { PLACES_SETTINGS_TTL_IN_DAYS } from './cache/database';
import { getOfflinePlacesSettings } from './cache/getOfflinePlacesSettings';
import { putOfflinePlacesSettings } from './cache/putOfflinePlacesSettings';
import { defaultPlacesSettings } from './fetchPlacesSettings';
import retryFetchPlacesSettings from './retryFetchPlacesSettings';
import {
    getPlacesSettings,
    hasLoaded,
    resetPlacesSettings,
    setPlacesSettings,
    setPlacesSettingsFailed,
} from './store';

import type { PlacesTenantSettingComplete } from './types/PlacesTenantSettingCompleteCallback';
import type { MailboxInfo } from 'owa-client-types';
import type { PlacesSettings } from './types/PlacesSettings';
import type { CustomDataMap } from 'owa-analytics-types';

export default function loadPlacesSettings(
    mailboxInfo: MailboxInfo,
    shouldUseMainThread?: boolean,
    onPlacesTenantSettingComplete?: PlacesTenantSettingComplete
) {
    const oldOnPlacesTenantSettingComplete = onPlacesTenantSettingComplete;
    let shouldRunCompleteCallback = true;
    onPlacesTenantSettingComplete = (...args) => {
        if (shouldRunCompleteCallback) {
            shouldRunCompleteCallback = false;
            oldOnPlacesTenantSettingComplete?.(...args);
        }
    };

    // Just copied from another file: packages/hybridspace/places-settings/src/fetchPlacesSettings.ts
    // Gradually rolling out license check to avoid regression.
    // Once this flight catches up with places-web-app-enabled, this flight can be removed.
    // See https://exp.microsoft.com/a/feature/5f1f2039-76ac-42a2-b884-2712236ede03?workspaceId=272e8f3b-4b43-4bac-9ca8-30f98bb3a3e4&group=/outlookprod/OutlookWeb
    // TODO clean up task: https://outlookweb.visualstudio.com/MicrosoftPlaces/_workitems/edit/346674
    /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
     * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
     * Task #346674 states that there are still many users where the flight check returns false, so removing the mailbox might have risks.
     *	> The parameter mailboxInfo must be undefined. Feature flight: 'msplaces-enable-license-check' */
    const shouldUsePlacesLicensing = isFeatureEnabled('msplaces-enable-license-check', mailboxInfo);

    const ensureCallComplete = isFeatureEnabled('msplaces-ensure-call-places-settings-complete');
    const isOfflineEnabled = isFeatureEnabled('msplaces-offline-places-settings');

    const options = { shouldUsePlacesLicensing, ensureCallComplete, isOfflineEnabled } as const;

    if (!hasLoaded(mailboxInfo)) {
        const bootStep = startAppBootStep('PlacesSettings', options);

        resetPlacesSettings(mailboxInfo);

        // Try to load offline settings first
        if (isOfflineEnabled) {
            loadOfflinePlacesSettings(mailboxInfo, { ...options, forceUseOfflineCache: false });
        }

        retryFetchPlacesSettings(mailboxInfo, shouldUseMainThread)
            .then(placesSettings => {
                // Update store with online settings
                setPlacesSettings(mailboxInfo, placesSettings);

                // Save to offline cache for future use
                if (isOfflineEnabled) {
                    putOfflinePlacesSettings(mailboxInfo, placesSettings);
                }
            })
            .catch(async err => {
                // Fallback
                const placesSettings: PlacesSettings = {
                    ...defaultPlacesSettings,
                    shouldUsePlacesLicensing,
                    reason: typeof err === 'string' ? err : err?.message,
                };

                bootStep.hasError = true;
                bootStep.caughtError = placesSettings.reason;

                if (isOfflineEnabled) {
                    // If the offline cache is in use, we will ignore the error and continue with the offline cache
                    if (hasLoaded(mailboxInfo)) {
                        return;
                    }

                    // Try to use the offline cache even if it indicates that the Places web app is disabled
                    const loaded = await loadOfflinePlacesSettings(mailboxInfo, {
                        ...options,
                        forceUseOfflineCache: true,
                    });

                    // Ignore the error if the offline cache was loaded successfully
                    if (loaded) {
                        return;
                    }
                }

                // Update store with error
                setPlacesSettingsFailed(mailboxInfo, placesSettings);
            })
            .finally(() => {
                // Call the complete callback
                onPlacesTenantSettingComplete?.(getPlacesSettings(mailboxInfo));
                bootStep.end();
            });
    } else if (ensureCallComplete) {
        // If the Places Settings have already been preloaded,
        // we manually trigger the "complete" callback to ensure that the caller is notified.
        onPlacesTenantSettingComplete?.(getPlacesSettings(mailboxInfo));
    }
}

async function loadOfflinePlacesSettings(
    mailboxInfo: MailboxInfo,
    options: Readonly<{
        shouldUsePlacesLicensing: boolean;
        ensureCallComplete: boolean;
        isOfflineEnabled: boolean;
        forceUseOfflineCache: boolean;
    }>
) {
    const bootStep = startAppBootStep('OfflinePlacesSettings', options);
    const customData: CustomDataMap = {};

    try {
        const result = await getOfflinePlacesSettings(mailboxInfo);

        if (!result) {
            throw new Error('No offline Places Settings found');
        }

        const { timestamp } = result;
        const timestampDate = userDate(timestamp);
        const expirationDate = addDays(timestampDate, PLACES_SETTINGS_TTL_IN_DAYS);
        const nowDate = now();

        if (isAfter(nowDate, expirationDate)) {
            Object.assign(customData, {
                timestamp: getISOString(timestampDate),
                expiration: getISOString(expirationDate),
                now: getISOString(nowDate),
            });
            throw new Error('Offline Places Settings expired');
        }

        // If the `fetchPlacesSettings` call has already completed, we don't need to use the offline result.
        if (hasLoaded(mailboxInfo)) {
            throw new Error('Offline Places Settings loaded after online call');
        }

        const placesSettings: PlacesSettings = {
            ...defaultPlacesSettings, // Ensure that all properties are present
            shouldUsePlacesLicensing: options.shouldUsePlacesLicensing, // Allow the offline result to override the default value
            ...result.placesSettings,
        };

        Object.assign(customData, {
            shouldUsePlacesLicensing: placesSettings.shouldUsePlacesLicensing,
            placesWebAppEnabled: placesSettings.placesWebAppEnabled,
            placesPremiumEnabled: placesSettings.placesPremiumEnabled,
            placesCopilotEnabled: placesSettings.placesCopilotEnabled,
            placesFinderEnabled: placesSettings.placesFinderEnabled,
            placesHybridGuidanceEnabled: placesSettings.placesHybridGuidanceEnabled,
        });

        // We will only consider using the offline result if it indicates that the Places web app is enabled.
        // Otherwise, we will wait for the online result.
        if (!placesSettings.placesWebAppEnabled && !options.forceUseOfflineCache) {
            throw new Error('Offline Places Settings said Places web app is disabled');
        }

        setPlacesSettings(mailboxInfo, placesSettings);

        return true;
    } catch (err) {
        bootStep.hasError = true;
        bootStep.caughtError = err;
        logGreyError('LoadOfflinePlacesSettingsError', err, customData);
        return false;
    } finally {
        bootStep.end(customData);
    }
}
