import { getUserSessionFromMetaOSContext } from 'hybridspace-telemetry';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { query } from 'owa-apollo';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { PlacesSettingsDocument } from './__generated__/queryPlacesSettings.interface';

import type { MailboxInfo } from 'owa-client-types';
import type { ResolverContext } from 'owa-graph-schema';

export default async function queryPlacesSettings(
    mailboxInfo: MailboxInfo,
    names: string[],
    shouldUseMainThread?: boolean,
    timeoutMS?: number,
    noBatching?: boolean,
    queryDeduplication?: boolean
) {
    const datapoint = new PerformanceDatapoint('QUERY_PLACES_SETTINGS');
    datapoint.addCustomData({ shouldUseMainThread, timeoutMS, noBatching, queryDeduplication });

    // Don't block the process
    // Include the MetaOS context for better telemetry
    getUserSessionFromMetaOSContext().then(metaOsContext => {
        if (!datapoint.hasEnded) {
            datapoint.addCustomData({ metaOsContext });
        }
    });

    try {
        const userSettings = getAccountScopeUserSettings(mailboxInfo);
        const result = await query(PlacesSettingsDocument, {
            variables: {
                mailboxId: `${userSettings?.SessionSettings?.ExternalDirectoryUserGuid}@${userSettings?.SessionSettings?.ExternalDirectoryTenantGuid}`,
                names,
            },
            context: {
                noBatching,
                requestOptions: {
                    mailboxInfo,
                    timeoutMS,
                },
                gatewayGraphRequest: !!shouldUseMainThread, // When true, send graphql request via main thread.
                queryDeduplication, // When false, allow multiple same queries to be sent to the server.
            } satisfies Partial<ResolverContext>,
        });
        datapoint.end();
        return result;
    } catch (error) {
        datapoint.endWithError(DatapointStatus.ServerError, error);
        return { data: undefined, error };
    }
}
