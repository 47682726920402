import { isFeatureEnabled } from 'owa-feature-flags';

import { WorkPlansDailyPeekSource } from './types';

export const isDailyPeekV2FeatureEnabled = (source?: string) => {
    const isDailyPeekV2ForWin32Enabled = isFeatureEnabled(
        'cal-surface-places-daily-peek-v2-win32-enable'
    );
    const isDailyPeekV2ForMyDayPaneEnabled = isFeatureEnabled(
        'cal-surface-places-daily-peek-v2-myDayPane-enable'
    );

    const isWin32Outlook = source === WorkPlansDailyPeekSource.OPX;
    const isSourceMyDayPane = source === WorkPlansDailyPeekSource.MyDayPane;

    return (
        isFeatureEnabled('cal-surface-places-daily-peek-v2') &&
        (!isWin32Outlook || isDailyPeekV2ForWin32Enabled) &&
        (!isSourceMyDayPane || isDailyPeekV2ForMyDayPaneEnabled)
    );
};
