import {
    parseMergedWorkLocationType,
    WorkLocationSurfaceType,
} from 'owa-calendar-working-hours-location-utils';
import { addDays, getTimestamp, startOfDay } from 'owa-datetime';
import { getOwaDateFromExtendedDateTime } from 'owa-datetime-utils';

import type {
    FlexibleWorkingHours,
    FlexibleWorkingHoursView,
    HybridspaceGetScheduleFlexibleWorkingHoursView,
    Location,
} from 'owa-graph-schema';
import type { OwaDate } from 'owa-datetime';

type FlexibleWorkingHoursSegment = Pick<
    FlexibleWorkingHours | FlexibleWorkingHoursView,
    'start' | 'end' | 'workLocationType' | 'workLocationDetails'
>;

type FlexibleWorkingHoursSummary = {
    date: OwaDate;
    timestamp: number;
    workLocationSurfaceType: WorkLocationSurfaceType;
    buildingId?: Location['id'];
    buildingIds: Map<Location['id'], Location['displayName']>;
    segments: FlexibleWorkingHoursSegment[];
};

export default function getFlexibleWorkingHoursSummaries(
    segments: HybridspaceGetScheduleFlexibleWorkingHoursView[] | FlexibleWorkingHoursSegment[]
): FlexibleWorkingHoursSummary[] {
    // Consider all OOF segments when calling parseMergedWorkLocationType,
    // since OOF segments can span multiple dates.
    const oofSegments = segments.filter(s => s.workLocationType == 'TimeOff');

    // Group segments by date and track the building IDs for each date.
    // A date can have multiple segments, of any kind.
    const summariesByDate = new Map<number, FlexibleWorkingHoursSummary>();
    for (const fwh of segments) {
        const date = startOfDay(getOwaDateFromExtendedDateTime(fwh.start));
        const timestamp = getTimestamp(date);

        if (!summariesByDate.has(timestamp)) {
            summariesByDate.set(timestamp, {
                date,
                timestamp,
                workLocationSurfaceType: WorkLocationSurfaceType.Unspecified,
                buildingIds: new Map(),
                segments: [],
            });
        }

        const dateInfo = summariesByDate.get(timestamp);
        if (dateInfo) {
            dateInfo.segments.push(fwh);
            // type check for compiler
            if ('building' in fwh) {
                const buildingDetails = fwh.building;
                if (!!buildingDetails && !!buildingDetails.id) {
                    dateInfo.buildingIds.set(buildingDetails.id, buildingDetails.name);
                }
            }
            // if passing in FlexibleWorkingHoursSegment, fallback to old logic
            else if (
                fwh.workLocationDetails?.locationType === 'Building' &&
                fwh.workLocationDetails.id
            ) {
                dateInfo.buildingIds.set(
                    fwh.workLocationDetails.id,
                    fwh.workLocationDetails.displayName
                );
            }
        }
    }

    // Determine the work location surface type for each date, taking OOF segments into account.
    for (const dateInfo of summariesByDate.values()) {
        const fromTime = getTimestamp(dateInfo.date);
        const toTime = getTimestamp(addDays(dateInfo.date, 1));
        const oofSegmentsForDate = oofSegments.filter(s => {
            const itemStart = getTimestamp(getOwaDateFromExtendedDateTime(s.start));
            const itemEnd = getTimestamp(getOwaDateFromExtendedDateTime(s.end));
            return (
                (itemStart >= fromTime && itemStart < toTime) ||
                (itemEnd > fromTime && itemEnd <= toTime) ||
                (itemStart <= fromTime && itemEnd >= toTime)
            );
        });

        dateInfo.buildingId = [...dateInfo.buildingIds.keys()][0];

        dateInfo.workLocationSurfaceType =
            parseMergedWorkLocationType([...dateInfo.segments, ...oofSegmentsForDate]) ??
            WorkLocationSurfaceType.Unspecified;
    }

    const summary = [...summariesByDate.values()];
    return summary;
}
