import type { MailboxInfo } from 'owa-client-types';
import { CalendarCloudSettingType } from '../store/schema/CalendarCloudSettingType';
import getCalendarCloudSettings from './getCalendarCloudSettings';

/**
 * Gets the value for the show coworkers organization calendar state cloud setting
 * @param mailboxInfo the mailbox info
 * @returns the value for the show coworkers organization calendar state cloud setting
 */
export function getOrgCalendarShowCoworkersCloudSetting(
    mailboxInfo: MailboxInfo
): boolean | undefined {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const orgCalendarShowCoworkers = accountSettings.get(
        CalendarCloudSettingType.OrgCalendarShowCoworkers.toLowerCase()
    );

    return orgCalendarShowCoworkers != null ? !!orgCalendarShowCoworkers.value : undefined;
}

/**
 * Gets the value for the show direct reports organization calendar state cloud setting
 * @param mailboxInfo the mailbox info
 * @returns the value for the show direct reports organization calendar state cloud setting
 */
export function getOrgCalendarShowDirectReportsCloudSetting(
    mailboxInfo: MailboxInfo
): boolean | undefined {
    const accountSettings = getCalendarCloudSettings(mailboxInfo);
    const orgCalendarShowDirectReports = accountSettings.get(
        CalendarCloudSettingType.OrgCalendarShowDirectReports.toLowerCase()
    );

    return orgCalendarShowDirectReports != null ? !!orgCalendarShowDirectReports.value : undefined;
}
