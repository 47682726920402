import { createStore } from 'owa-satcheljs';
import type { RoomLocation } from './types/roomLocation';

export const getStore = createStore('hybridspace-isAttendeeRoomState', {
    // Contains the cache of places emailIds and whether it is a room or not
    isAttendeeARoomMap: new Map<string, boolean>(),
    roomToBuildingMap: new Map<string, RoomLocation>(),
});

export function isAttendeeCached(email: string): boolean {
    return getStore().isAttendeeARoomMap.has(email);
}

export function isAttendeeARoom(email: string): boolean {
    return !!getStore().isAttendeeARoomMap.get(email);
}

export function getBuildingForRoom(email: string): RoomLocation | undefined {
    return getStore().roomToBuildingMap.get(email);
}
