//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import type * as Types from 'owa-graph-schema';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateFlexibleWorkingHoursMutationVariables = Types.Exact<{
    input: Types.UpdateFlexibleWorkingHoursRequest;
}>;
export type UpdateFlexibleWorkingHoursMutation = {
    __typename?: 'Mutation';
    updateFlexibleWorkingHours?: {
        __typename?: 'UpdateFlexibleWorkingHoursResponse';
        segment?: {
            __typename?: 'FlexibleWorkingHours';
            id: string;
        } | null;
    } | null;
};
export const UpdateFlexibleWorkingHoursDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'UpdateFlexibleWorkingHours' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UpdateFlexibleWorkingHoursRequest' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateFlexibleWorkingHours' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'request' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'segment' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateFlexibleWorkingHoursMutation,
    UpdateFlexibleWorkingHoursMutationVariables
>;
