/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. DO NOT COPY AND PASTE!
 *	> 'runInAction' import from 'mobx' is restricted. */
import { runInAction } from 'mobx';
import { loadUpsCollaboratorsBuildingLocationsSucceeded } from 'places-ups-location-operations';
import { orchestrator } from 'satcheljs';
import {
    getCollaboratorsOutOfficeStatus,
    setCollaboratorsInMeetingsStatus,
    setCollaboratorsInOfficeStatus,
    setCollaboratorsOutOfficeStatus,
    setLastServicePeopleInMeetingsSuccessfullyCompleted,
    setLastServicePeopleInOfficeSuccessfullyCompleted,
} from '../store';

// when the UPS collaborators are loaded successfully, update the collaborators in office status
orchestrator(loadUpsCollaboratorsBuildingLocationsSucceeded, actionMessage => {
    const category = actionMessage.collaboratorCategory;
    const setUpsFunction =
        category === 'collaborator'
            ? setCollaboratorsInOfficeStatus
            : setCollaboratorsInMeetingsStatus;
    const setLastServiceSuccessfullyCompleted =
        category === 'collaborator'
            ? setLastServicePeopleInOfficeSuccessfullyCompleted
            : setLastServicePeopleInMeetingsSuccessfullyCompleted;

    const baseUpsCollaborators = actionMessage.result;
    // filter the data to remove collaborators with empty primarySmtpAddress and out of office collaborators
    const filteredUPSData = baseUpsCollaborators.filter(
        collaborator => !collaborator?.isOutOfOffice
    );

    // get the OOF people from the store
    const getCurrentOutOfOfficePeople = getCollaboratorsOutOfficeStatus() ?? [];
    const outOfOfficePeople = filteredUPSData
        .filter(collaborator => collaborator?.isOutOfOffice)
        .map(collaborator => collaborator.primarySmtpAddress);

    // Combine OOF and remove duplicates
    const newOutOfOfficePeople = [
        ...new Set([...outOfOfficePeople, ...getCurrentOutOfOfficePeople]),
    ];

    // Run the following code in a mobx action since it is technically updating observable maps.
    runInAction(() => {
        setUpsFunction(filteredUPSData);
        setCollaboratorsOutOfficeStatus(newOutOfOfficePeople);
        setLastServiceSuccessfullyCompleted(true);
    });
});
