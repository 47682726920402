import { isAccountFeatureEnabled, isFeatureEnabled } from 'owa-feature-flags';
import { getAccountScopeUserSettings } from 'owa-session-store';
import createMultiAccountStore from 'owa-viewstate-store/lib/createMultiAccountStore';
import { mutatorAction } from 'satcheljs';

import type { AccountFeatureName } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import type { PlacesSettings } from './types/PlacesSettings';

const LINKEDIN_DOMAINS = ['linkedin.com', 'linkedin.biz', 'internal.linkedin.cn'];

const MICROSOFT_TENANT_ID = '72f988bf-86f1-41af-91ab-2d7cd011db47';

const [read, write] = createMultiAccountStore('PLACES_SETTINGS', {
    loading: true,
    failed: false,
    reason: undefined as string | undefined,
    shouldUsePlacesLicensing: false as boolean | undefined,
    placesWebAppEnabled: false,
    placesPremiumEnabled: false,
    placesCopilotEnabled: false,
    placesFinderEnabled: false,
    placesHybridGuidanceEnabled: false,
});

export function isLoading(mailboxInfo: MailboxInfo) {
    const store = read(mailboxInfo);
    return getValue(
        mailboxInfo,
        'places-dev-settings-loading-enabled',
        'places-dev-settings-loading-disabled',
        store.loading
    );
}

export function hasFailed(mailboxInfo: MailboxInfo) {
    const store = read(mailboxInfo);
    return getValue(
        mailboxInfo,
        'places-dev-settings-failed-enabled',
        'places-dev-settings-failed-disabled',
        store.failed
    );
}

export function hasLoaded(mailboxInfo: MailboxInfo) {
    const store = read(mailboxInfo);
    return !store.loading && !store.failed;
}

export function isPlacesWebAppEnabled(mailboxInfo: MailboxInfo) {
    const store = read(mailboxInfo);
    return getValue(
        mailboxInfo,
        'places-dev-settings-webapp-enabled',
        'places-dev-settings-webapp-disabled',
        store.placesWebAppEnabled
    );
}

export function isPlacesPremiumEnabled(mailboxInfo: MailboxInfo) {
    const store = read(mailboxInfo);
    return getValue(
        mailboxInfo,
        'places-dev-settings-premium-enabled',
        'places-dev-settings-premium-disabled',
        store.placesPremiumEnabled
    );
}

export function isPlacesCopilotEnabled(mailboxInfo: MailboxInfo) {
    const store = read(mailboxInfo);
    return (
        isPlacesPremiumEnabled(mailboxInfo) &&
        getValue(
            mailboxInfo,
            'places-dev-settings-copilot-enabled',
            'places-dev-settings-copilot-disabled',
            store.placesCopilotEnabled
        )
    );
}

export function isPlacesFinderEnabled(mailboxInfo: MailboxInfo) {
    const store = read(mailboxInfo);
    const tenantSettingEnabled =
        isPlacesPremiumEnabled(mailboxInfo) &&
        getValue(
            mailboxInfo,
            'places-dev-settings-finder-enabled',
            'places-dev-settings-finder-disabled',
            store.placesFinderEnabled
        );
    return (
        !isLinkedInUser(mailboxInfo) &&
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'cal-use-places-finder' */
        (tenantSettingEnabled || isFeatureEnabled('cal-use-places-finder', mailboxInfo))
    );
}

export function isOpxPlacesFinderEnabled(mailboxInfo: MailboxInfo) {
    const store = read(mailboxInfo);
    const tenantSettingEnabled =
        isPlacesPremiumEnabled(mailboxInfo) &&
        getValue(
            mailboxInfo,
            'places-dev-settings-finder-enabled',
            'places-dev-settings-finder-disabled',
            store.placesFinderEnabled
        );
    return (
        !isLinkedInUser(mailboxInfo) &&
        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
         *	> The parameter mailboxInfo must be undefined. Feature flight: 'cal-opx-use-places-finder' */
        (tenantSettingEnabled || isFeatureEnabled('cal-opx-use-places-finder', mailboxInfo))
    );
}

export function isPlacesHybridGuidanceEnabled(mailboxInfo: MailboxInfo) {
    const store = read(mailboxInfo);
    return (
        isPlacesPremiumEnabled(mailboxInfo) &&
        getValue(
            mailboxInfo,
            'places-dev-settings-hybridguidance-enabled',
            'places-dev-settings-hybridguidance-disabled',
            store.placesHybridGuidanceEnabled
        )
    );
}

function getValue(
    mailboxInfo: MailboxInfo,
    enabled: AccountFeatureName,
    disabled: AccountFeatureName,
    storeValue: boolean
) {
    return isAccountFeatureEnabled(enabled, mailboxInfo)
        ? true
        : isAccountFeatureEnabled(disabled, mailboxInfo)
        ? false
        : storeValue;
}

function isLinkedInUser(mailboxInfo: MailboxInfo) {
    const sessionSettings = getAccountScopeUserSettings(mailboxInfo)?.SessionSettings;

    return (
        sessionSettings?.ExternalDirectoryTenantGuid == MICROSOFT_TENANT_ID &&
        LINKEDIN_DOMAINS.some(x => x == sessionSettings?.TenantDomain?.toLowerCase())
    );
}

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const resetPlacesSettings = mutatorAction(
    'RESET_PLACES_SETTINGS',
    (mailboxInfo: MailboxInfo) => {
        const store = write(mailboxInfo);
        store.loading = true;
        store.failed = false;
        store.reason = undefined;
        store.shouldUsePlacesLicensing = false;
        store.placesWebAppEnabled = false;
        store.placesPremiumEnabled = false;
        store.placesCopilotEnabled = false;
        store.placesFinderEnabled = false;
        store.placesHybridGuidanceEnabled = false;
    }
);

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setPlacesSettings = mutatorAction(
    'SET_PLACES_SETTINGS',
    (mailboxInfo: MailboxInfo, newValues: PlacesSettings) => {
        const store = write(mailboxInfo);
        store.loading = false;
        store.failed = false;
        store.reason = newValues.reason;
        store.shouldUsePlacesLicensing = newValues.shouldUsePlacesLicensing;
        store.placesWebAppEnabled = newValues.placesWebAppEnabled;
        store.placesPremiumEnabled = newValues.placesPremiumEnabled;
        store.placesCopilotEnabled = newValues.placesCopilotEnabled;
        store.placesFinderEnabled = newValues.placesFinderEnabled;
        store.placesHybridGuidanceEnabled = newValues.placesHybridGuidanceEnabled;
    }
);

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setPlacesSettingsFailed = mutatorAction(
    'SET_PLACES_SETTINGS_FAILED',
    (mailboxInfo: MailboxInfo, fallback: PlacesSettings) => {
        const store = write(mailboxInfo);
        store.loading = false;
        store.failed = true;
        store.reason = fallback.reason;
        store.shouldUsePlacesLicensing = fallback.shouldUsePlacesLicensing;
        store.placesWebAppEnabled = fallback.placesWebAppEnabled;
        store.placesPremiumEnabled = fallback.placesPremiumEnabled;
        store.placesCopilotEnabled = fallback.placesCopilotEnabled;
        store.placesFinderEnabled = fallback.placesFinderEnabled;
        store.placesHybridGuidanceEnabled = fallback.placesHybridGuidanceEnabled;
    }
);

export function getPlacesSettings(mailboxInfo: MailboxInfo): PlacesSettings {
    const store = read(mailboxInfo);
    return {
        reason: store.reason,
        shouldUsePlacesLicensing: store.shouldUsePlacesLicensing,
        placesWebAppEnabled: store.placesWebAppEnabled,
        placesPremiumEnabled: store.placesPremiumEnabled,
        placesCopilotEnabled: store.placesCopilotEnabled,
        placesFinderEnabled: store.placesFinderEnabled,
        placesHybridGuidanceEnabled: store.placesHybridGuidanceEnabled,
    };
}
