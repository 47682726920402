import type { CalendarEvent, Maybe } from 'owa-graph-schema';
import { getBuildingForRoom } from '../store';
import getReservedRoomEmailAddresses from './getReservedRoomEmailAddresses';

export default function hasRoomsReservedInBuilding(
    event: CalendarEvent,
    buildingId?: Maybe<string>
): boolean {
    const reservedRooms = getReservedRoomEmailAddresses(event);
    if (reservedRooms.length == 0) {
        return false;
    }

    // if no specific building is provided, then we return true if any room is reserved
    // otherwise check if any of the reserved rooms are in the specified building
    return (
        !buildingId ||
        reservedRooms.some(room => {
            return getBuildingForRoom(room)?.building.id === buildingId;
        })
    );
}
