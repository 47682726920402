import { HybridspaceError } from 'hybridspace-common/lib/components/HybridspaceError';
import { logGreyError } from 'owa-analytics';
import { type HttpStatusCode } from 'owa-http-status-codes';
import { observer } from 'owa-mobx-react';
import React from 'react';
import {
    isRouteErrorResponse,
    useNavigate,
    useNavigation,
    useRevalidator,
    useRouteError,
} from 'react-router-dom';
import {
    returnToCollaborators,
    workGroupNotFoundMessage,
    workGroupRetryActionButtonLabel,
    workGroupRetryMessage,
    workGroupNotFoundTitle,
} from './PlacesWorkGroupsErrorBoundary.locstring.json';
import { container } from './PlacesErrorBoundary.scss';
import { type UTPageLoadStatus, type UTPageType } from 'owa-unified-telemetry';
import { logPlacesEvent } from 'hybridspace-telemetry';

export default observer(function PlacesWorkGroupsErrorBoundary({
    errorName,
    errorStatus,
}: {
    errorName?: string;
    errorStatus?: HttpStatusCode;
}) {
    const revalidator = useRevalidator();
    const navigate = useNavigate();
    const navigation = useNavigation();

    const routeError = useRouteError() as any;
    const isRouteError = isRouteErrorResponse(routeError);

    const status = !!errorStatus ? errorStatus : isRouteError ? routeError?.status : 500;

    const message = status === 404 ? workGroupNotFoundMessage : workGroupRetryMessage;

    const title = status === 404 ? workGroupNotFoundTitle : undefined;

    const buttonTitle = status === 404 ? returnToCollaborators : workGroupRetryActionButtonLabel;

    const reload = React.useCallback(() => {
        switch (status) {
            // If not found, navigate to the home page
            case 404:
                navigate('/people');
                break;
            // Else we revalidate the route
            default:
                revalidator.revalidate();
                break;
        }
    }, [revalidator, navigate, status]);

    // UT Logs for for specific error names
    React.useEffect(() => {
        // Generic log for debugging.
        logGreyError('PlacesWorkGroupErrorBoundary', routeError, {
            errorName,
            errorStatus,
        });
        logPlacesEvent({
            eventName: 'PlacesPageLoad',
            data: {
                PageStatus: 404 ? 'NotFound' : 'Error',
                PageType: 'WorkGroups',
            },
        });
    }, [errorName, errorStatus, routeError]);

    const isLoading = navigation.state === 'loading' || revalidator.state != 'idle';

    return (
        <div className={container}>
            <HybridspaceError
                retry={reload}
                errorMessage={message}
                buttonLabel={buttonTitle}
                brandedButtonStyle={true}
                loading={isLoading}
                errorTitle={title}
                hideIcon={true}
                dataTelemetryId={"'PlacesWorkGroupsErrorBoundaryButton'"}
            />
        </div>
    );
},
'PlacesErrorBoundary');
