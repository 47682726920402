//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import type * as Types from 'owa-graph-schema';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetListUserFeatureAccessQueryVariables = Types.Exact<{
    [key: string]: never;
}>;
export type GetListUserFeatureAccessQuery = {
    __typename?: 'Query';
    listUserFeatureAccess: {
        __typename?: 'ListUserFeatureAccessResponse';
        featureAccess: Array<Types.Features | null>;
    };
};
export const GetListUserFeatureAccessDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetListUserFeatureAccess' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listUserFeatureAccess' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'featureAccess' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetListUserFeatureAccessQuery, GetListUserFeatureAccessQueryVariables>;
