import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CalendarCloudSettings" */ './lazyIndex'),
    { name: 'CalendarCloudSettings' }
);

export const lazySaveAccountCalendarCloudSettings = new LazyAction(
    lazyModule,
    m => m.saveAccountCalendarCloudSettings
);

export const lazyInitCalendarCloudSettings = new LazyAction(
    lazyModule,
    m => m.initCalendarCloudSettings
);

export { calendarAccountCloudSettingUpdated } from './actions/calendarAccountCloudSettingUpdated';

export { default as getShortenEventScopeCloudSetting } from './selectors/getShortenEventScopeCloudSetting';
export { default as getCalendarFilterCloudSettings } from './selectors/getCalendarFilterCloudSettings';
export { default as getEndShortEventsByCloudSetting } from './selectors/getEndShortEventsByCloudSetting';
export { default as getEndLongEventsByCloudSetting } from './selectors/getEndLongEventsByCloudSetting';
export { default as getEnableOnlineMeetingsByDefaultCloudSetting } from './selectors/getEnableOnlineMeetingsByDefaultCloudSetting';
export { default as getEventSetReminderCloudSetting } from './selectors/getEventSetReminderCloudSetting';
export {
    default as getIsCloudSettingsStoreInitialized,
    getAreAllAccountCloudSettingsStoreInitialized,
} from './selectors/getIsCloudSettingsStoreInitialized';
export { default as getDefaultShouldShortenEventDuration } from './utils/getDefaultShouldShortenEventDuration';
export { default as getDefaultShouldShortenEventDurationEndEarly } from './utils/getDefaultShouldShortenEventDurationEndEarly';
export { default as getDefaultShouldShortenEventDurationStartLate } from './utils/getDefaultShouldShortenEventDurationStartLate';
export { default as getDefaultEndShortEventsBy } from './utils/getDefaultEndShortEventsBy';
export { default as getDefaultEndLongEventsBy } from './utils/getDefaultEndLongEventsBy';
export { default as getDefaultOnlineMeetingsEnabled } from './utils/getDefaultOnlineMeetingsEnabled';
export { default as getDefaultEventSetReminder } from './utils/getDefaultEventSetReminder';
export { default as isSpeedyMeetingSetByTenant } from './utils/isSpeedyMeetingSetByTenant';
export { default as getDefaultVisibleRoamingTimezones } from './utils/getDefaultVisibleRoamingTimezones';
export { default as getDefaultPrimaryTimezoneLabel } from './utils/getDefaultPrimaryTimezoneLabel';
export { default as getTimezoneLabelForTimezoneId } from './utils/getTimezoneLabelForTimezoneId';
export { default as hasMultipleTimezonesConfigured } from './utils/hasMultipleTimezonesConfigured';
export { default as getDefaultRoamingTimezones } from './utils/getDefaultRoamingTimezones';
export { default as getDefaultShortenEventScope } from './utils/getDefaultShortenEventScope';
export { default as getGlobalCalendarLocale } from './utils/getGlobalCalendarLocale';
export { default as getGlobalCalendarEnabledCloudSetting } from './selectors/getGlobalCalendarEnabledCloudSetting';
export { default as getGlobalCalendarLanguageCloudSetting } from './selectors/getGlobalCalendarLanguageCloudSetting';
export { default as getGlobalCalendarTypeCloudSetting } from './selectors/getGlobalCalendarTypeCloudSetting';
export { default as getRokuyouDaysCloudSetting } from './selectors/getRokuyouDaysCloudSetting';
export { default as getHijriOffsetCloudSetting } from './selectors/getHijriOffsetCloudSetting';
export { default as getDefaultShouldShowWorkingHoursOnSurface } from './utils/getDefaultShouldShowWorkingHoursOnSurface';
export { default as getHybridRSVPEnabled } from './utils/getHybridRSVPEnabled';
export { default as getLeftPanelInitOcsState } from './utils/getLeftPanelInitOcsState';
export { default as getOrgCalendarShowCoworkersEnabled } from './utils/getOrgCalendarShowCoworkersEnabled';
export { default as getOrgCalendarShowDirectReportsEnabled } from './utils/getOrgCalendarShowDirectReportsEnabled';
export { CalendarCloudSettingScopeType } from './store/schema/CalendarCloudSettingRequestEnums';
export { CalendarCloudSettingType } from './store/schema/CalendarCloudSettingType';
export { ShortenEventScopeType } from './store/schema/ShortenEventScopeType';
export type { default as CalendarCloudSetting } from './store/schema/CalendarCloudSetting';
export type { default as RoamingTimezone } from './store/schema/RoamingTimezone';
export { MAX_COUNT_ROAMING_TIMEZONES } from './store/schema/RoamingTimezone';
export {
    CalendarEventsFilterType,
    CalendarEventsFilters,
    GeneralFilters,
    MeetingsFilters,
    RecurrenceFilters,
    PrivacyFilters,
    DeclinedEventsFilters,
    CalendarFiltersTypes,
    MeetingsFiltersV2,
    AttendeeMeetingFilters,
    OrganizerMeetingFilters,
    CalendarEventsFiltersV2,
    InPersonFilters,
} from './store/schema/CalendarEventsFilters';
