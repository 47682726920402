import { getDayLocation } from 'hybridspace-common/lib/utils';
import { getDateRangeISOString } from 'hybridspace-common/lib/utils/getDateRangeISOString';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. DO NOT COPY AND PASTE!
 *	> 'runInAction' import from 'mobx' is restricted. */
import { runInAction } from 'mobx';
import { WorkLocationSurfaceType } from 'owa-calendar-working-hours-location-utils';
import { isSegmentEncapsulated } from 'owa-calendar-working-hours-utils';
import {
    addDays,
    getTimestamp,
    isSameDay,
    owaDate,
    startOfDay,
    startOfWeek,
    today,
} from 'owa-datetime';
import { getDatesInDateRanges } from 'owa-datetime-utils';
import { isGuid } from 'owa-guid';
import { getUserOptions, getUserTimeZone } from 'owa-session-store';
import { loadCollaboratorsLocationsSucceeded } from 'places-explore-operations';
import { getCurrentDateRange, setAvailableBuildings } from 'places-user-session-stores';
import { orchestrator } from 'satcheljs';
import { setCollaboratorsInCache } from './selectors/setCollaboratorsInCache';
import { addPeopleInfoToCache } from './selectors/addPeopleInfoToCache';
import { cleanPlacesPeopleCache } from './selectors/cleanPlacesPeopleCache';
import {
    getCollaboratorScheduleLocations,
    getCollaboratorsOfficeLocations,
    setCollaboratorLocationsServiceCallComplete,
    setCollaboratorScheduleLocations,
    setCollaboratorsOfficeLocations,
    setSavedCollaborators,
    setSuggestedCollaborators,
} from './store';

import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';
import type {
    HybridspaceGetScheduleFlexibleWorkingHoursView,
    WorkLocationType,
    ExtendedDateTime,
} from 'owa-graph-schema';
import type { PeopleInfo } from './database';
/**
 * This orchestrator responds to the loadCollaboratorsLocationsSucceeded action and updates the store & Cache with data
 * We update PeopleCache saved & suggested collaborator tables
 * We update PeopleCache peopleInfo table
 * We update store values for collaborators and their locations (places in a map to easily ready swimlane & inOffice data)
 */
orchestrator(loadCollaboratorsLocationsSucceeded, ({ result, dateRange }) => {
    const timeZone = getUserTimeZone();

    // cleaning cache
    const userStartOfWeek = getUserOptions()?.WeekStartDay || 0; /** default to Sunday */
    cleanPlacesPeopleCache(startOfWeek(today(), userStartOfWeek));

    // Check data is valid
    // Not valid if there are no data
    if (result.collaboratorScheduleWithLocation.length === 0) {
        return;
    }

    // adding to cache
    const peopleInfo: PeopleInfo[] = [];
    // Map to put in the store once data is ready
    const collaboratorLocationMap = getCollaboratorScheduleLocations();
    const collaboratorsOfficeLocations = getCollaboratorsOfficeLocations();
    const collaboratorLocations = result.collaboratorScheduleWithLocation;
    const savedCollaborators = result.savedCollaborators;
    const suggestedCollaborators = result.suggestedCollaborators;

    // Run the following code in a mobx action since it is technically updating observable maps.
    runInAction(() => {
        // Combine saved and suggested collaborators with saved on top
        const saveAndSuggestedCollaborators = [...savedCollaborators, ...suggestedCollaborators];

        //Get all the dates in the date range
        const dates = getDatesInDateRanges(dateRange.start, dateRange.end, [dateRange]);
        dates.push(addDays(dateRange.end, 1), addDays(dateRange.start, -1));

        // Set default values for in office summary data
        dates.forEach(date => {
            const dateNumber = getTimestamp(startOfDay(owaDate(timeZone, date)));
            if (!collaboratorsOfficeLocations.has(dateNumber)) {
                collaboratorsOfficeLocations.set(dateNumber, new Map<string, string[]>());
            }
        });

        // Set Default values for swim lane data
        saveAndSuggestedCollaborators.forEach(collaborator => {
            if (!collaboratorLocationMap.has(collaborator.primarySmtpAddress)) {
                collaboratorLocationMap.set(collaborator.primarySmtpAddress, new Map());
            }
        });

        // Process data for:
        // swim lane and in office store values
        // indexDB cache
        collaboratorLocations.forEach(({ schedule, collaborator }) => {
            if (!schedule || !collaborator) {
                return;
            }

            const fwhList = schedule.flexibleWorkingHours;
            const collaboratorSource = collaborator.source || 'Suggested';
            const collaboratorEmail = collaborator.primarySmtpAddress as UserEmailAddress;

            if (!fwhList || !collaboratorEmail) {
                return;
            }

            // skip this collaborator schedule is they are not saved/suggested
            const dateAndLocationMap = collaboratorLocationMap.get(collaboratorEmail);
            if (dateAndLocationMap === undefined) {
                return;
            }

            // If we have an error, store value "Error" for all dates
            // Move on to next collaborator schedule
            const scheduleError = schedule.error;
            if (scheduleError != null) {
                dates.forEach(date => {
                    const formatDate = owaDate(timeZone, date);
                    // Add to map
                    const dateNumber = getTimestamp(startOfDay(formatDate));
                    dateAndLocationMap.set(dateNumber, { workLocationSurfaceType: 'Error' });
                    // Add to cache
                    peopleInfo.push({
                        date: formatDate,
                        location: 'Error',
                        email: collaboratorEmail,
                        collaboratorType: collaboratorSource,
                    });
                });
                return;
            }

            // for each FWH item, check if there are duplicate schedules for a date
            // Store the duplicates in sameDatesFWH and the rest in refinedFWH
            const sameDatesFWH: Array<HybridspaceGetScheduleFlexibleWorkingHoursView[]> = [];
            const refinedFWH: HybridspaceGetScheduleFlexibleWorkingHoursView[] = [];
            // Whether the FWH item has been processed
            const processedFWH = Array(fwhList.length).fill(false);
            for (let i = 0; i < fwhList.length; i++) {
                // Skip if already processed
                if (processedFWH[i]) {
                    continue;
                }

                const currentGroup = [];
                currentGroup.push(fwhList[i]);
                processedFWH[i] = true;

                // Compare all the FWH items with eachother
                // If they are on the same day, add them to the sameDatesFWH
                // Else add them to refinedFWH
                for (let j = i + 1; j < fwhList.length; j++) {
                    const iTime = owaDate(
                        fwhList[i].start.timeZone.name,
                        fwhList[i].start.dateTime
                    );
                    const jTime = owaDate(
                        fwhList[j].start.timeZone.name,
                        fwhList[j].start.dateTime
                    );

                    if (!processedFWH[j] && isSameDay(iTime, jTime)) {
                        currentGroup.push(fwhList[j]);
                        processedFWH[j] = true;
                    }
                }

                if (currentGroup.length > 1) {
                    sameDatesFWH.push(currentGroup);
                } else {
                    refinedFWH.push(...currentGroup);
                }
            }

            const outofofficeSegments: Array<{
                start: ExtendedDateTime;
                end: ExtendedDateTime;
            }> = [];

            // For each of the duplicate schedule FWH, we use getDayLocation to determine the location for the day
            /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
             * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
             *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead
             *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
            sameDatesFWH.forEach(fwh => {
                const currentDate = owaDate(fwh[0].start.timeZone.name, fwh[0].start.dateTime);
                const workLocationType = getDayLocation(currentDate, fwh) as WorkLocationType;

                // If the location is TimeOff, push it to outofofficeSegments
                // works as a reference to check if the location is TimeOff
                const segmentToCheck = { start: fwh[0].start, end: fwh[0].end };
                if (workLocationType === 'TimeOff') {
                    outofofficeSegments.push(segmentToCheck);
                }

                // check if the fwh selected is encapsulated by the outofofficeSegments
                const isSegmentOOF = isSegmentEncapsulated(segmentToCheck, outofofficeSegments);

                const fwhToUse =
                    fwh.find(item => item.workLocationType === workLocationType) ?? fwh[0];
                const newFWHItem: HybridspaceGetScheduleFlexibleWorkingHoursView = {
                    ...fwhToUse,
                    workLocationType: isSegmentOOF ? 'TimeOff' : workLocationType,
                };
                refinedFWH.push(newFWHItem);
            });

            /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
             * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
             *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead
             *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead
             *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead
             *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
            refinedFWH.forEach((fwh: HybridspaceGetScheduleFlexibleWorkingHoursView) => {
                let dateString = fwh.start.dateTime;
                //Away segments are defaulted to be at midnight, so we need to adjust the time to 5:00 PM
                //so we dont see date shift in other timezones
                const segmentToCheck = { start: fwh.start, end: fwh.end };
                if (fwh.workLocationType === 'TimeOff') {
                    outofofficeSegments.push(segmentToCheck);
                    dateString = moveDateTo5PM(dateString);
                }

                const isSegmentOOF = isSegmentEncapsulated(segmentToCheck, outofofficeSegments);
                const collaboratorDate = owaDate(timeZone, dateString);

                const timeStampedDate = getTimestamp(startOfDay(owaDate(timeZone, dateString)));
                //Prepare data for in office summary
                const locationInfo = isSegmentOOF
                    ? 'TimeOff'
                    : fwh.building?.id ?? fwh.workLocationType;
                dateAndLocationMap.set(timeStampedDate, {
                    workLocationSurfaceType: isSegmentOOF
                        ? WorkLocationSurfaceType.TimeOff
                        : toWorkLocationSurfaceType(fwh.workLocationType),
                    buildingId: fwh.building?.id,
                });

                const isOfficeLocation =
                    !isSegmentOOF && (fwh.workLocationType === 'Office' || fwh.building?.id);

                if (isOfficeLocation) {
                    const buildingsForDate = collaboratorsOfficeLocations.get(timeStampedDate);

                    if (buildingsForDate?.has(locationInfo)) {
                        // Check that email does not exist in map already
                        if (
                            collaboratorsOfficeLocations
                                .get(timeStampedDate)
                                ?.get(locationInfo)
                                ?.indexOf(collaboratorEmail) === -1
                        ) {
                            collaboratorsOfficeLocations
                                ?.get(timeStampedDate)
                                ?.get(locationInfo)
                                ?.push(collaboratorEmail);
                        }
                    } else {
                        collaboratorsOfficeLocations
                            .get(timeStampedDate)
                            ?.set(locationInfo, [collaboratorEmail]);
                    }
                }

                peopleInfo.push({
                    date: collaboratorDate,
                    location: locationInfo,
                    email: collaboratorEmail,
                    collaboratorType: collaboratorSource,
                });
            });
            //Swim lane data
            collaboratorLocationMap.set(collaboratorEmail, dateAndLocationMap);
        });

        // Store layer
        setCollaboratorScheduleLocations(collaboratorLocationMap);
        setSuggestedCollaborators(suggestedCollaborators);
        setSavedCollaborators(savedCollaborators);
        setCollaboratorsOfficeLocations(collaboratorsOfficeLocations);

        // Set Building filter options for current date range only
        const buildingSet = new Set<string>();
        const key = getDateRangeISOString(dateRange);
        const currentDateRange = getCurrentDateRange();
        const currentDateRangeKey = getDateRangeISOString(currentDateRange);
        if (key === currentDateRangeKey) {
            getDatesInDateRanges(currentDateRange.start, currentDateRange.end, [
                currentDateRange,
            ]).forEach(date => {
                const dateNumber = getTimestamp(startOfDay(owaDate(timeZone, date)));
                const value = collaboratorsOfficeLocations.get(dateNumber);
                if (value) {
                    value.forEach((_collaborator, buildingId) => {
                        if (isGuid(buildingId)) {
                            buildingSet.add(buildingId);
                        }
                    });
                }
            });
            setAvailableBuildings(Array.from(buildingSet));
        }

        // cache layer
        const savedCollaboratorsEmails = savedCollaborators.map(
            collab => collab.primarySmtpAddress
        );
        const suggestedCollaboratorsEmails = suggestedCollaborators.map(
            collab => collab.primarySmtpAddress
        );
        addPeopleInfoToCache(peopleInfo);
        setCollaboratorsInCache(savedCollaboratorsEmails, 'Saved');
        setCollaboratorsInCache(suggestedCollaboratorsEmails, 'Suggested');

        setCollaboratorLocationsServiceCallComplete(dateRange);
    });
});

function moveDateTo5PM(dateTime: string): string {
    // Parse the date part and the timezone part
    const datePart = dateTime.slice(0, 10); // "2024-06-14"
    const timezonePart = dateTime.slice(19); // "-06:00"
    // New time part
    const newTimePart = '17:00:00';
    // Construct the new datetime string
    return `${datePart}T${newTimePart}${timezonePart}`;
}

function toWorkLocationSurfaceType(value: WorkLocationType): WorkLocationSurfaceType {
    return {
        Office: WorkLocationSurfaceType.Office,
        Remote: WorkLocationSurfaceType.Remote,
        TimeOff: WorkLocationSurfaceType.TimeOff,
        Unspecified: WorkLocationSurfaceType.Unspecified,
    }[value];
}
