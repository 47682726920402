import { logGreyError } from 'owa-analytics';
import { getItem, removeItem, setItem } from 'owa-local-storage';

import type { PlacesSettings } from '../types/PlacesSettings';

export const PLACES_SETTINGS_STORAGE_KEY = 'places_placesSettings';
export const PLACES_SETTINGS_TTL_IN_DAYS = 30;

export type PlacesSettingsCache = {
    [accountKey in string]?: PlacesSettingsCacheEntry;
};

export interface PlacesSettingsCacheEntry {
    accountKey: string;
    timestamp: number;
    placesSettings: PlacesSettings;
}

export function getPlacesSettingsCache() {
    try {
        const cache = getItem(self, PLACES_SETTINGS_STORAGE_KEY);
        return typeof cache === 'string' ? (JSON.parse(cache) as PlacesSettingsCache) : undefined;
    } catch (err) {
        logGreyError('GetPlacesSettingsCacheError', err);
        return undefined;
    }
}

export function setPlacesSettingsCache(cache: PlacesSettingsCache) {
    try {
        setItem(self, PLACES_SETTINGS_STORAGE_KEY, JSON.stringify(cache));
    } catch (err) {
        logGreyError('SetPlacesSettingsCacheError', err);
    }
}

export function deletePlacesSettingsCache() {
    removeItem(self, PLACES_SETTINGS_STORAGE_KEY);
}
