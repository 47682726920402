import { logGreyError, logUsage } from 'owa-analytics';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';
import { getPlacesSettingsCache } from './database';

import type { MailboxInfo } from 'owa-client-types';

export function getOfflinePlacesSettings(mailboxInfo: MailboxInfo) {
    try {
        const placesSettingsCache = getPlacesSettingsCache();

        const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);

        const result = placesSettingsCache?.[accountKey];

        if (result) {
            logUsage('GetOfflinePlacesSettingsSuccess', {
                PlacesWebAppEnabled: result.placesSettings.placesWebAppEnabled,
                PlacesPremiumEnabled: result.placesSettings.placesPremiumEnabled,
                PlacesCopilotEnabled: result.placesSettings.placesCopilotEnabled,
                PlacesFinderEnabled: result.placesSettings.placesFinderEnabled,
                PlacesHybridGuidanceEnabled: result.placesSettings.placesHybridGuidanceEnabled,
            });
        }

        return result;
    } catch (err) {
        logGreyError('GetOfflinePlacesSettingsError', err);
        return undefined;
    }
}
