import type { CalendarEvent, Location, CalendarParticipant, ResponseType } from 'owa-graph-schema';
import { isAttendeeARoom } from '../store';

export default function getRoomLocationDetails(event: CalendarEvent): {
    locationNames: string | undefined;
    resourceStatus: ResponseType | undefined;
    isTeamsMeeting: boolean;
} {
    const locations = event?.locations as Location[];
    const resources = (event?.meetingDetails?.resources || []) as CalendarParticipant[];
    const declinedValues = ['Decline', null, undefined];
    const msTeamsMeetingID = 'Microsoft Teams Meeting';

    let locationNames = undefined;
    let resourceStatus = undefined;
    let isTeamsMeeting = false;

    const roomLocations = locations?.filter(
        location => location?.locationType === 'ConferenceRoom'
    );

    // Resources could be rooms, desks or equipment. So we need to filter all resources which are not rooms.
    const roomResources = resources.filter(
        resource =>
            !!roomLocations.find(
                (room: Location) => room?.id === resource?.userIdentity?.emailAddress
            )
    );

    // Look at the resource attendees for the rooms as well.
    // If the room is not in the locations, then we need to check the resources attendees since the server side code to stamp
    // the locations collection with conf rooms is not WW yet
    const resourceAttendees = (event?.meetingDetails?.resources || []) as CalendarParticipant[];
    const resourceRoomAttendees = resourceAttendees.filter(
        attendee =>
            !!attendee?.userIdentity?.emailAddress &&
            !roomResources.find(
                room => room?.userIdentity?.emailAddress === attendee?.userIdentity?.emailAddress
            ) /* Exclude the roomResources already identified as rooms since we don't need to call getPlace for those */ &&
            isAttendeeARoom(attendee.userIdentity.emailAddress)
    );

    roomResources.push(...resourceRoomAttendees);

    const acceptedRooms = roomResources?.filter(
        resource => resource?.responseStatus?.responseType === 'Accept'
    );

    const declinedRooms = roomResources?.filter(
        resource => declinedValues.indexOf(resource?.responseStatus?.responseType) > -1
    );

    // TODO: What should we do for tentative rooms

    if (roomResources.length > 0) {
        if (acceptedRooms.length > 0) {
            locationNames = acceptedRooms.map(room => room?.userIdentity?.name).toString();
            resourceStatus = 'Accept' as ResponseType;
        } else if (declinedRooms.length > 0) {
            locationNames = declinedRooms.map(room => room?.userIdentity?.name).toString();
            if (!locationNames) {
                // get the email of the declined room if the name is not available
                const declinedRoomsEmails = declinedRooms.filter(
                    room => room?.userIdentity?.emailAddress
                );
                // ensure that the locationNames only contains declined room information
                // and display the first room's name from the declined rooms
                locationNames = locations
                    .filter(location => {
                        const currentRoomEmail = location?.id;
                        // if currentRoomEmail is falsy no need to search for match in declinedRoomsEmails
                        return currentRoomEmail
                            ? declinedRoomsEmails.some(
                                  room => room?.userIdentity?.emailAddress === currentRoomEmail
                              )
                            : false;
                    })
                    .map(location => location?.displayName)
                    .toString();
            }
            resourceStatus = 'Decline' as ResponseType;
        }
    }

    if (!locationNames) {
        locationNames = locations?.[0]?.displayName?.toString();
        isTeamsMeeting = locations?.[0]?.id === msTeamsMeetingID;
    }

    return {
        locationNames,
        resourceStatus,
        isTeamsMeeting,
    };
}
