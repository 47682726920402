import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PlacesTimeZoneHelpers"*/ './lazyIndex'),
    { name: 'PlacesTimeZoneHelpers' }
);

export const PlacesTimeZoneDialog = createLazyComponent(lazyModule, m => m.SetTimeZoneDialog);

export { isUserTimeZoneSet } from './utils/isUserTimeZoneSet';
