import { type UTOpenWorkPlanPeekOrigin } from 'owa-unified-telemetry';
import { WorkPlansDailyPeekSource } from './types';

export function getWorkPlanPeekOrigin(source: string | null): UTOpenWorkPlanPeekOrigin {
    switch (source) {
        case WorkPlansDailyPeekSource.Surface:
            return 'CalendarPeekView';
        case WorkPlansDailyPeekSource.MyDayPane:
            return 'MyDayPane';
        case WorkPlansDailyPeekSource.Places:
            return 'PlacesApp';
        default:
            return 'CalendarPeekView';
    }
}
