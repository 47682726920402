/** copilot -hub-main-module has been added as a POC and will be used for demo purpose. Please remove this module if the feature didnt make it to the production. */

import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CopilotHubMainModule" */ './lazyIndex'),
    { name: 'CopilotHubMainModule' }
);

export const CopilotHubMainModule = createLazyComponent(lazyModule, m => m.CopilotHubMainModule);
