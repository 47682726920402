import type { MailboxInfo } from 'owa-client-types';
import { getOrgCalendarShowCoworkersCloudSetting } from '../selectors/getOrgCalendarCloudSetting';

const ORG_CALENDAR_SHOW_COWORKERS_DEFAULT = true;
/**
 * Gets the default value for the show coworkers organization calendar state
 * @param mailboxInfo the mailbox info
 * @returns the boolean value for the show coworkers organization calendar state
 */
export default function getOrgCalendarShowCoworkersEnabled(mailboxInfo: MailboxInfo): boolean {
    const orgCalendarShowCoworkers = getOrgCalendarShowCoworkersCloudSetting(mailboxInfo);
    return orgCalendarShowCoworkers != null
        ? orgCalendarShowCoworkers
        : ORG_CALENDAR_SHOW_COWORKERS_DEFAULT;
}
