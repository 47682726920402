import { mutatorAction } from 'satcheljs';
import { getStore } from './store';
import type { RoomLocation } from './types/roomLocation';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setIsAttendeeARoom = mutatorAction(
    'setIsAttendeeARoom',
    (email: string, isRoom: boolean) => {
        getStore().isAttendeeARoomMap.set(email, isRoom);
    }
);

export const setBuildingForRoomMutator = mutatorAction(
    'setBuildingForRoomMutator',
    (email: string, roomLocation: RoomLocation) => {
        getStore().roomToBuildingMap.set(email, roomLocation);
    }
);
