import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';
import { GetPlacesDocument } from 'hybridspace-graphql/lib/graphql/__generated__/getPlacesQuery.interface';
import { logGreyError } from 'owa-analytics';
import { writeQuery } from 'owa-apollo';
import { getTimestamp, subWeeks, today } from 'owa-datetime';
import type { Place } from 'owa-graph-schema';
import type { BuildingsCache } from './database';
import { getBuildingsTable } from './database';

export async function writePlaceCacheToApollo() {
    const buildingsTable = getBuildingsTable();

    if (!buildingsTable) {
        return;
    }

    const ownerId = getUserExternalDirectoryId();

    try {
        const buildingData = await buildingsTable
            .where({
                owner: ownerId,
            } satisfies Partial<BuildingsCache>)
            .toArray();

        const sevenDaysAgo = getTimestamp(subWeeks(today(), 1));

        if (buildingData.length > 0) {
            await Promise.all(
                buildingData.map(async building => {
                    const place = JSON.parse(building.building);
                    const valid = validateValidPlace(place); // make sure required fields are present
                    if (!valid) {
                        return;
                    }

                    // Check if the timestamp is more than 7 days old
                    if (building.timestamp && building.timestamp < sevenDaysAgo) {
                        await buildingsTable.delete(building.uniqueId); // Delete outdated cache entry
                        return;
                    }

                    const querystring = `Id = "${place.id}"`;
                    writeQuery(GetPlacesDocument, {
                        variables: { placesRequest: { query: querystring } },
                        data: { places: { places: [place as Place] } },
                    });
                })
            );
        }
    } catch (e) {
        logGreyError('WritePlaceCacheToApollo', e);
    }
}

function validateValidPlace(building: any): boolean {
    const id = building.id;
    const name = building.name;
    const type = building.type;
    return !!id && !!name && !!type;
}
