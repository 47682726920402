import { logGreyError } from 'owa-analytics';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';
import { getTimestamp, now } from 'owa-datetime';
import { getPlacesSettingsCache, setPlacesSettingsCache } from './database';

import type { MailboxInfo } from 'owa-client-types';
import type { PlacesSettings } from '../types/PlacesSettings';

export function putOfflinePlacesSettings(mailboxInfo: MailboxInfo, placesSettings: PlacesSettings) {
    try {
        const placesSettingsCache = getPlacesSettingsCache();

        const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);

        setPlacesSettingsCache({
            ...placesSettingsCache,
            [accountKey]: {
                accountKey,
                timestamp: getTimestamp(now()),
                placesSettings,
            },
        });
    } catch (err) {
        logGreyError('PutOfflinePlacesSettingsError', err);
    }
}
