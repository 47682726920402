import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PlacesDiagnostics"*/ './lazyIndex'),
    { name: 'PlacesDiagnostics' }
);

export const PlacesCopilotDiagnosticsPanel = createLazyComponent(
    lazyModule,
    m => m.PlacesCopilotDiagnosticsPanel
);

export const PlacesExploreDiagnosticsPanel = createLazyComponent(
    lazyModule,
    m => m.PlacesExploreDiagnosticsPanel
);

export {
    getDevPageState,
    getDevErrorPageState,
    getDevComponentState,
} from './store/explorePageDiagnosticsStoreGetters';
