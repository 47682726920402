/**
 * Calendar cloud global setting names
 */
export enum CalendarCloudGlobalSettingType {
    ShouldEventsEndEarly = 'Should_Event_End_Early',
    ShortenEventScope = 'shorten_event_scope',
    EndShortEventsBy = 'End_Short_meetings_by',
    EndLongEventsBy = 'End_long_meetings_by',
    EnableOnlineMeetingsByDefault = 'onlinemeetings_enabledbydefault',
    RoamingTimezones = 'roaming_timezones',
    PrimaryTimezoneLabel = 'primary_timezone_label',
    GlobalCalendarEnabled = 'global_calendar_enabled',
    GlobalCalendarLanguage = 'global_calendar_language',
    GlobalCalendarType = 'global_calendar_type',
    RokuyouDays = 'rokuyou_days',
    HijriOffset = 'hijri_offset',
    ShouldShowWorkingHoursOnSurface = 'show_working_hours_on_surface',
    HybridRSVPEnabled = 'hybrid_rsvp_enabled',
    CalendarFilters = 'calendar_filters',
    EventSetReminder = 'calendar_apptreminders',
    SendDeleteWhenRespond = 'mail_senddeletewhenrespond',
    LeftPanelInitState = 'calendar_sidepanelinitstate',
}

/**
 * Calendar cloud account setting names
 */
export enum CalendarCloudAccountSettingType {
    OrgCalendarShowCoworkers = 'cal_orgcalendarshowcoworkers',
    OrgCalendarShowDirectReports = 'cal_orgcalendarshowdirectreports',
}

/**
 * Calendar cloud setting names includes both global and account settings
 */
export const CalendarCloudSettingType = {
    ...CalendarCloudGlobalSettingType,
    ...CalendarCloudAccountSettingType,
};
export type CalendarCloudSettingType =
    | CalendarCloudGlobalSettingType
    | CalendarCloudAccountSettingType;
