import { createLazyComponent, LazyAction, LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "DeskBooking"*/ './lazyIndex'),
    { name: 'DeskBooking' }
);

export const DeskBookingSuggestion = createLazyComponent(lazyModule, m => m.DeskBookingSuggestion);
export const HybridspaceWorkspace = createLazyComponent(lazyModule, m => m.HybridspaceWorkspace);
export const LocationChangeDialog = createLazyComponent(lazyModule, m => m.LocationChangeDialog);
export const WorkspaceBookingList = createLazyComponent(lazyModule, m => m.WorkspaceBookingList);
export const HybridspaceBookAWorkspace = createLazyComponent(
    lazyModule,
    m => m.HybridspaceBookAWorkspace
);

export const lazyGetDeskSuggestionForDay = new LazyImport(
    lazyModule,
    m => m.getDeskSuggestionForDay
);

export const lazyInitializeDeskBookingSuggestion = new LazyAction(
    lazyModule,
    m => m.initializeDeskBookingSuggestion
);

export const lazyGetWorkspaceReservationsInBuildingForDay = new LazyImport(
    lazyModule,
    m => m.getWorkspaceReservationsInBuildingForDay
);

export const lazyBookDesk = new LazyImport(lazyModule, m => m.bookDesk);

export const lazyLoadWorkspaceReservations = new LazyImport(
    lazyModule,
    m => m.loadWorkspaceReservations
);

export const lazyGetWorkspaceReservationsForDay = new LazyImport(
    lazyModule,
    m => m.getWorkspaceReservationsForDay
);

export const lazyGenerateCollaboratorSubtitle = new LazyImport(
    lazyModule,
    m => m.generateCollaboratorSubtitle
);
