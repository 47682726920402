import { getUserSessionFromMetaOSContext } from 'hybridspace-telemetry';
import { DatapointStatus, logUsage, PerformanceDatapoint } from 'owa-analytics';
import { apolloErrorToResponseCode } from 'owa-apollo-errors/lib/apolloErrorToResponseCode';
import { getISOString, userDate } from 'owa-datetime';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getGuid } from 'owa-guid';
import { isAuthStatusCode } from 'owa-http-status-codes';
import fetchPlacesSettings, { PLACES_SETTINGS_RETRY_BACKOFF_MS } from './fetchPlacesSettings';

import type { MailboxInfo } from 'owa-client-types';
import type { PlacesSettings } from './types/PlacesSettings';
import type { CustomDataMap } from 'owa-analytics-types';

export default async function retryFetchPlacesSettings(
    mailboxInfo: MailboxInfo,
    shouldUseMainThread?: boolean
): Promise<PlacesSettings> {
    const datapoint = new PerformanceDatapoint('RETRY_FETCH_PLACES_SETTINGS');
    const isRetryEnabled = isFeatureEnabled('msplaces-fetch-places-settings-concurrently');
    const trackId = getGuid();
    const startTime = performance.now();
    let hasError = false;
    let caughtError: Error | undefined;
    const timers: NodeJS.Timeout[] = [];
    let retryCount = 0;
    let successIndex = -1;

    const custom1: CustomDataMap = {
        TrackId: trackId,
        Step: 'Start',
        IsRetryEnabled: isRetryEnabled,
        StartTime: getISOString(userDate(startTime + performance.timeOrigin)),
        RetryCount: retryCount,
        SuccessIndex: successIndex,
        ShouldUseMainThread: shouldUseMainThread,
    };

    // Consider the 60s timeout case, so we need to include the following information now
    datapoint.addCustomData({ ...custom1 });

    try {
        // Sending the Start log alone without waiting for the End,
        // so that we know it happened even if the WINDOW IS SUDDENLY CLOSED.
        getUserSessionFromMetaOSContext().then(metaOsContext => {
            logUsage('RetryFetchPlacesSettings', { ...custom1, metaOsContext });

            if (!datapoint.hasEnded) {
                datapoint.addCustomData({ metaOsContext });
            }
        });

        const fetch = (options?: { noBatching?: boolean; queryDeduplication?: boolean }) => {
            return fetchPlacesSettings(
                mailboxInfo,
                shouldUseMainThread,
                undefined /* timeoutMS */,
                options?.noBatching,
                options?.queryDeduplication
            );
        };

        if (!isRetryEnabled) {
            return await fetch();
        }

        // Retry the request
        return await new Promise<PlacesSettings>((resolve, reject) => {
            Promise.allSettled<void>([
                // Same as in the original code
                fetch()
                    .then(result => {
                        if (successIndex === -1) {
                            successIndex = 0;
                            resolve(result); // Resolve ASAP to avoid waiting for the `Promise.allSettled` to resolve
                        }
                    })
                    .catch(err => {
                        const responseCode = err && apolloErrorToResponseCode(err);

                        // Stop retrying if the error is an auth error since more retries won't help
                        if (isAuthStatusCode(Number(responseCode))) {
                            reject(err);
                        }

                        throw err;
                    }),

                // Start retry after a backoff
                ...PLACES_SETTINGS_RETRY_BACKOFF_MS.map((backoff, index) => {
                    return new Promise<void>((resolve2, reject2) => {
                        const timer = setTimeout(() => {
                            retryCount += 1;
                            fetch({
                                noBatching: true, // Disable batching to avoid waiting for other requests in the batch
                                queryDeduplication: false, // Disable to allow multiple same requests to be sent concurrently
                            })
                                .then(result => {
                                    if (successIndex === -1) {
                                        successIndex = index + 1;
                                        resolve(result); // Resolve ASAP to avoid waiting for the `Promise.allSettled` to resolve
                                    }
                                    resolve2();
                                })
                                .catch(err => {
                                    reject2(err);
                                });
                        }, backoff);

                        // Clear the timer in the finally block
                        timers.push(timer);
                    });
                }),
            ]).then(([result]) => {
                if (result.status === 'rejected') {
                    reject(result.reason); // All failed, sending out the first error
                }
            });
        });
    } catch (err) {
        hasError = true;
        caughtError = err;
        throw err;
    } finally {
        timers.forEach(clearTimeout);

        const endTime = performance.now();

        getUserSessionFromMetaOSContext().then(metaOsContext => {
            const custom2: CustomDataMap = {
                TrackId: trackId,
                Step: hasError ? 'Error' : 'Success',
                IsRetryEnabled: isRetryEnabled,
                StartTime: getISOString(userDate(startTime + performance.timeOrigin)),
                EndTime: getISOString(userDate(endTime + performance.timeOrigin)),
                Duration: endTime - startTime,
                RetryCount: retryCount,
                SuccessIndex: successIndex,
                ShouldUseMainThread: shouldUseMainThread,
                metaOsContext,
                error: caughtError instanceof Error ? caughtError.message : undefined,
            };

            datapoint.addCustomData(custom2);

            if (hasError) {
                datapoint.endWithError(DatapointStatus.ServerError, caughtError);
            } else {
                datapoint.end();
            }

            // Also log the error to `logUsage` for easier tracking
            logUsage('RetryFetchPlacesSettings', custom2);
        });
    }
}
