import type { CalendarEvent, CalendarParticipant, Location } from 'owa-graph-schema';
import hasRoom from './hasRoom';

export default function getReservedRoomEmailAddresses(event: CalendarEvent): string[] {
    if (!hasRoom(event)) {
        return [];
    }

    const locations = (event?.locations || []) as Location[];
    const resources = (event?.meetingDetails?.resources || []) as CalendarParticipant[];

    const roomLocationEmails = locations
        .filter(location => location.id && location?.locationType === 'ConferenceRoom')
        .map(location => location?.id as string);

    const reservedResourceEmails = resources
        .filter(
            resource =>
                resource.userIdentity?.emailAddress &&
                resource.responseStatus?.responseType === 'Accept'
        )
        .map(resource => resource.userIdentity?.emailAddress as string);

    const reservedRoomEmails = [...new Set([...roomLocationEmails, ...reservedResourceEmails])];

    return reservedRoomEmails;
}
