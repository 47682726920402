import { logStartGreyError } from 'owa-analytics-start';
import { LazyAction, LazyBootModule } from 'owa-bundling-light';

export const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "PlacesSignout" */ './lazyIndex'),
    { name: 'PlacesSignout' }
);

export const lazyCleanUpPlacesLocalStorage = new LazyAction(
    lazyModule,
    m => m.cleanUpPlacesLocalStorage
);

export const lazyCleanUpPlacesSessionStorage = new LazyAction(
    lazyModule,
    m => m.cleanUpPlacesSessionStorage
);

export const lazyCleanUpPlacesIndexedDB = new LazyAction(lazyModule, m => m.cleanUpPlacesIndexedDB);

export const lazyCleanUpPlacesOfflineSync = new LazyAction(
    lazyModule,
    m => m.cleanUpPlacesOfflineSync
);

// Preload the module to expect that it is loaded when needed
lazyModule.importModule('PlacesSignoutPreload')?.catch(err => {
    logStartGreyError('PlacesSignoutPreloadError', err);
});
