import './observableTodayChangedOrchestrator';
import { createLazyComponent, LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HybridspaceAgendaPanel" */ './lazyIndex'),
    { name: 'HybridspaceAgendaPanel' }
);

export const HybridspaceAgendaPanel = createLazyComponent(
    lazyModule,
    m => m.HybridspaceAgendaPanel
);

export const lazyHybridspaceAgendaPanelModule = new LazyImport(lazyModule, m => m);
