//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import type * as Types from 'owa-graph-schema';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SharedFullCalendarEventFragmentFragment = {
    __typename?: 'CalendarEvent';
    id: string;
    calendarId?: string | null;
    globalObjectId?: string | null;
    requestedAttendanceMode?: Types.RequestedAttendanceMode | null;
    seriesId?: string | null;
    categories?: Array<string> | null;
    instanceKey?: string | null;
    lastModifiedTime?: string | null;
    creationTime?: string | null;
    sensitivity?: Types.SensitivityType | null;
    subject?: string | null;
    calendarItemType?: Types.CalendarItemType | null;
    charmId?: number | null;
    freeBusyType?: Types.BusyType | null;
    isAllDayEvent?: boolean | null;
    preview?: string | null;
    intendedStartTimeZone?: string | null;
    intendedEndTimeZone?: string | null;
    hasBlockedImages?: boolean | null;
    hasAttachments?: boolean | null;
    isOrganizer?: boolean | null;
    locationPreview?: string | null;
    entityNamesMap?: number | null;
    changeKey?: string | null;
    popupRemindersData?: Array<{
        __typename?: 'PopupReminderData';
        reminderMinutesBeforeStart?: number | null;
        reminderIsSet?: boolean | null;
    } | null> | null;
    onlineMeetingDetails?: {
        __typename?: 'OnlineMeetingDetails';
        joinUrl?: string | null;
        provider?: string | null;
        conferenceId?: string | null;
        tollNumber?: Array<string | null> | null;
        tollFreeNumbers?: Array<string | null> | null;
    } | null;
    start?: {
        __typename?: 'ExtendedDateTime';
        dateTime: string;
        timeZone: {
            __typename?: 'TimeZoneWH';
            name: string;
        };
    } | null;
    end?: {
        __typename?: 'ExtendedDateTime';
        dateTime: string;
        timeZone: {
            __typename?: 'TimeZoneWH';
            name: string;
        };
    } | null;
    meetingDetails?: {
        __typename?: 'MeetingDetails';
        hideAttendees?: boolean | null;
        isCancelled?: boolean | null;
        isResponseRequested?: boolean | null;
        isRoomRequested?: boolean | null;
        isSeriesCancelled?: boolean | null;
        doNotForwardMeeting?: boolean | null;
        isDraft?: boolean | null;
        replyName?: string | null;
        meetingRequestWasSent?: boolean | null;
        responseType?: Types.ResponseType | null;
        replyTime?: {
            __typename?: 'ExtendedDateTime';
            dateTime: string;
            timeZone: {
                __typename?: 'TimeZoneWH';
                name: string;
            };
        } | null;
        optionalAttendees?: Array<{
            __typename?: 'CalendarParticipant';
            responseStatus?: {
                __typename?: 'ResponseStatus';
                responseType?: Types.ResponseType | null;
                lastResponseTime?: string | null;
                responseComment?: string | null;
                proposedStart?: string | null;
                proposedEnd?: string | null;
                responseMode?: number | null;
            } | null;
            userIdentity?: {
                __typename?: 'ParticipantIdentity';
                name?: string | null;
                emailAddress?: string | null;
                routingType?: Types.AddressType | null;
            } | null;
        } | null> | null;
        requiredAttendees?: Array<{
            __typename?: 'CalendarParticipant';
            responseStatus?: {
                __typename?: 'ResponseStatus';
                responseType?: Types.ResponseType | null;
                lastResponseTime?: string | null;
                responseComment?: string | null;
                proposedStart?: string | null;
                proposedEnd?: string | null;
                responseMode?: number | null;
            } | null;
            userIdentity?: {
                __typename?: 'ParticipantIdentity';
                name?: string | null;
                emailAddress?: string | null;
                routingType?: Types.AddressType | null;
            } | null;
        } | null> | null;
        resources?: Array<{
            __typename?: 'CalendarParticipant';
            responseStatus?: {
                __typename?: 'ResponseStatus';
                responseType?: Types.ResponseType | null;
                lastResponseTime?: string | null;
                responseComment?: string | null;
                proposedStart?: string | null;
                proposedEnd?: string | null;
                responseMode?: number | null;
            } | null;
            userIdentity?: {
                __typename?: 'ParticipantIdentity';
                name?: string | null;
                emailAddress?: string | null;
                routingType?: Types.AddressType | null;
            } | null;
        } | null> | null;
    } | null;
    allowedActions?: {
        __typename?: 'CalendarAllowedActions';
        canWrite?: boolean | null;
        canShare?: boolean | null;
        canViewPrivateItems?: boolean | null;
        canRemove?: boolean | null;
        canRead?: boolean | null;
    } | null;
    body?: {
        __typename?: 'Body';
        bodyType?: Types.BodyType | null;
        value: string;
        isTruncated?: boolean | null;
    } | null;
    locations?: Array<{
        __typename?: 'Location';
        displayName?: string | null;
        annotation?: string | null;
        id?: string | null;
        idType?: Types.LocationIdType | null;
        locationType?: Types.LocationEntityType | null;
        locationUri?: string | null;
        locationSource?: Types.LocationSourceType | null;
        postalAddress?: {
            __typename?: 'PersonaPostalAddress';
            street?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            postalCode?: string | null;
            postOfficeBox?: string | null;
            type?: Types.PostalAddressType | null;
            formattedAddress?: string | null;
        } | null;
        coordinates?: {
            __typename?: 'Coordinates';
            latitude?: number | null;
            longitude?: number | null;
            accuracy?: number | null;
            altitude?: number | null;
            altitudeAccuracy?: number | null;
        } | null;
    } | null> | null;
    organizer?: {
        __typename?: 'CalendarParticipant';
        responseStatus?: {
            __typename?: 'ResponseStatus';
            responseType?: Types.ResponseType | null;
            lastResponseTime?: string | null;
            responseComment?: string | null;
            proposedStart?: string | null;
            proposedEnd?: string | null;
            responseMode?: number | null;
        } | null;
        userIdentity?: {
            __typename?: 'ParticipantIdentity';
            name?: string | null;
            emailAddress?: string | null;
            routingType?: Types.AddressType | null;
        } | null;
    } | null;
    conversationId?: {
        __typename?: 'SharedItemId';
        id: string;
    } | null;
    deprecatedParentFolderId?: {
        __typename?: 'SharedItemId';
        id: string;
    } | null;
    location?: {
        __typename?: 'Location';
        displayName?: string | null;
        annotation?: string | null;
        id?: string | null;
        idType?: Types.LocationIdType | null;
        locationType?: Types.LocationEntityType | null;
        locationUri?: string | null;
        locationSource?: Types.LocationSourceType | null;
        postalAddress?: {
            __typename?: 'PersonaPostalAddress';
            street?: string | null;
            city?: string | null;
            state?: string | null;
            country?: string | null;
            postalCode?: string | null;
            postOfficeBox?: string | null;
            type?: Types.PostalAddressType | null;
            formattedAddress?: string | null;
        } | null;
        coordinates?: {
            __typename?: 'Coordinates';
            latitude?: number | null;
            longitude?: number | null;
            accuracy?: number | null;
            altitude?: number | null;
            altitudeAccuracy?: number | null;
        } | null;
    } | null;
};
export type DateTimeFieldsFragment = {
    __typename?: 'ExtendedDateTime';
    dateTime: string;
    timeZone: {
        __typename?: 'TimeZoneWH';
        name: string;
    };
};
export type ParticipantFieldsFragment = {
    __typename?: 'CalendarParticipant';
    responseStatus?: {
        __typename?: 'ResponseStatus';
        responseType?: Types.ResponseType | null;
        lastResponseTime?: string | null;
        responseComment?: string | null;
        proposedStart?: string | null;
        proposedEnd?: string | null;
        responseMode?: number | null;
    } | null;
    userIdentity?: {
        __typename?: 'ParticipantIdentity';
        name?: string | null;
        emailAddress?: string | null;
        routingType?: Types.AddressType | null;
    } | null;
};
export type LocationFieldsFragment = {
    __typename?: 'Location';
    displayName?: string | null;
    annotation?: string | null;
    id?: string | null;
    idType?: Types.LocationIdType | null;
    locationType?: Types.LocationEntityType | null;
    locationUri?: string | null;
    locationSource?: Types.LocationSourceType | null;
    postalAddress?: {
        __typename?: 'PersonaPostalAddress';
        street?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        postalCode?: string | null;
        postOfficeBox?: string | null;
        type?: Types.PostalAddressType | null;
        formattedAddress?: string | null;
    } | null;
    coordinates?: {
        __typename?: 'Coordinates';
        latitude?: number | null;
        longitude?: number | null;
        accuracy?: number | null;
        altitude?: number | null;
        altitudeAccuracy?: number | null;
    } | null;
};
export const DateTimeFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DateTimeFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExtendedDateTime' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeZone' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DateTimeFieldsFragment, unknown>;
export const ParticipantFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ParticipantFields' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CalendarParticipant' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responseStatus' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'responseType' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastResponseTime' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'responseComment' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'proposedStart' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'proposedEnd' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'responseMode' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userIdentity' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'routingType' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ParticipantFieldsFragment, unknown>;
export const LocationFieldsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'LocationFields' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Location' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'annotation' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postalAddress' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'postOfficeBox' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'formattedAddress' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'idType' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'locationType' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coordinates' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accuracy' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'altitude' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altitudeAccuracy' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'locationUri' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'locationSource' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LocationFieldsFragment, unknown>;
export const SharedFullCalendarEventFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SharedFullCalendarEventFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CalendarEvent' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'calendarId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'globalObjectId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'requestedAttendanceMode' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'seriesId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'instanceKey' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'lastModifiedTime' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'creationTime' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'popupRemindersData' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'reminderMinutesBeforeStart' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'reminderIsSet' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'sensitivity' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'calendarItemType' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'charmId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'freeBusyType' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'isAllDayEvent' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onlineMeetingDetails' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'joinUrl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'conferenceId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'tollNumber' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'tollFreeNumbers' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'preview' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'DateTimeFields' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'intendedStartTimeZone' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'end' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'DateTimeFields' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'intendedEndTimeZone' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hasBlockedImages' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'hasAttachments' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'meetingDetails' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'replyTime' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'DateTimeFields' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'hideAttendees' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isCancelled' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isResponseRequested' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'isRoomRequested' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isSeriesCancelled' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'doNotForwardMeeting' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'isDraft' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'replyName' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'optionalAttendees' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ParticipantFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'requiredAttendees' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ParticipantFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'resources' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ParticipantFields' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'meetingRequestWasSent' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'responseType' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allowedActions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'canWrite' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'canShare' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'canViewPrivateItems' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'canRemove' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'canRead' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'body' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'bodyType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isTruncated' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'isOrganizer' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'locationPreview' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locations' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'LocationFields' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organizer' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ParticipantFields' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'conversationId' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deprecatedParentFolderId' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'entityNamesMap' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'changeKey' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'LocationFields' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...DateTimeFieldsFragmentDoc.definitions,
        ...ParticipantFieldsFragmentDoc.definitions,
        ...LocationFieldsFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<SharedFullCalendarEventFragmentFragment, unknown>;
