import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PlacesAnalyticsStore" */ './lazyIndex'),
    { name: 'PlacesAnalyticsStore' }
);

export const lazyLoadPlacesAnalyticsCoreOperations = new LazyAction(
    lazyModule,
    m => m.loadPlacesAnalyticsCoreOperations
);

export { useListUserFeatureAccessSelector } from './selectors/useListUserFeatureAccessSelector';
