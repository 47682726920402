//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import type * as Types from 'owa-graph-schema';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ComposeExtensionFragmentFragment = {
    __typename?: 'MessageExtension';
    botId?: string | null;
    canUpdateConfiguration?: boolean | null;
    scopes?: Array<Types.AppScope | null> | null;
    commandTypes?: Array<Types.InputExtensionCommandType | null> | null;
    commands?: Array<{
        __typename?: 'InputExtensionCommand';
        context?: Array<Types.InputExtensionCommandContext | null> | null;
        fetchTask?: boolean | null;
        description?: string | null;
        id?: string | null;
        initialRun?: boolean | null;
        title?: string | null;
        type?: Types.InputExtensionCommandType | null;
        parameters?: Array<{
            __typename?: 'InputExtensionCommandParameter';
            name?: string | null;
            title?: string | null;
            description?: string | null;
        } | null> | null;
    } | null> | null;
    messageHandlers?: Array<{
        __typename?: 'InputExtensionMessageHandler';
        value?: {
            __typename?: 'InputExtensionMessageHandlerValue';
            domains?: Array<string | null> | null;
        } | null;
    } | null> | null;
};
export type TitleDefinitionFragment = {
    __typename?: 'Title';
    name: string;
    accentColor?: string | null;
    ingestionId: string;
    blockStatus?: boolean | null;
    ingestionSource?: Types.IngestionSource | null;
    categories?: Array<string> | null;
    developerName?: string | null;
    developerUrl?: string | null;
    isFullTrust?: boolean | null;
    shortDescription?: string | null;
    longDescription?: string | null;
    permissions?: Array<string> | null;
    showLoadingIndicator?: boolean | null;
    scope?: Types.TitleScope | null;
    cultureName?: string | null;
    termsOfUseUrl?: string | null;
    validDomains?: Array<string> | null;
    version?: string | null;
    manifestVersion?: string | null;
    elementDefinitions?: {
        __typename?: 'ElementDefinition';
        composeExtensions?: Array<{
            __typename?: 'MessageExtension';
            botId?: string | null;
            canUpdateConfiguration?: boolean | null;
            scopes?: Array<Types.AppScope | null> | null;
            commandTypes?: Array<Types.InputExtensionCommandType | null> | null;
            commands?: Array<{
                __typename?: 'InputExtensionCommand';
                context?: Array<Types.InputExtensionCommandContext | null> | null;
                fetchTask?: boolean | null;
                description?: string | null;
                id?: string | null;
                initialRun?: boolean | null;
                title?: string | null;
                type?: Types.InputExtensionCommandType | null;
                parameters?: Array<{
                    __typename?: 'InputExtensionCommandParameter';
                    name?: string | null;
                    title?: string | null;
                    description?: string | null;
                } | null> | null;
            } | null> | null;
            messageHandlers?: Array<{
                __typename?: 'InputExtensionMessageHandler';
                value?: {
                    __typename?: 'InputExtensionMessageHandlerValue';
                    domains?: Array<string | null> | null;
                } | null;
            } | null> | null;
        }> | null;
    } | null;
    iconSmall?: {
        __typename?: 'M365AcquisitionIcon';
        uri: string;
    } | null;
    iconLarge?: {
        __typename?: 'M365AcquisitionIcon';
        uri: string;
    } | null;
    iconOutline?: {
        __typename?: 'M365AcquisitionIcon';
        uri: string;
    } | null;
    authorization?: {
        __typename?: 'AppAuthorization';
        permissions?: {
            __typename?: 'AppPermissions';
            orgWide?: Array<{
                __typename?: 'AppPermissionsNode';
                name?: string | null;
                type?: Types.AppPermissionsNodeType | null;
            } | null> | null;
            resourceSpecific?: Array<{
                __typename?: 'AppPermissionsNode';
                name?: string | null;
                type?: Types.AppPermissionsNodeType | null;
            } | null> | null;
        } | null;
    } | null;
    webApplicationInfo?: {
        __typename?: 'WebApplicationInfo';
        id?: string | null;
        resource?: string | null;
    } | null;
};
export type TitleLaunchInfoQueryVariables = Types.Exact<{
    titleId: Types.Scalars['String'];
    mailboxInfo?: Types.InputMaybe<Types.MailboxInfoInput>;
}>;
export type TitleLaunchInfoQuery = {
    __typename?: 'Query';
    m365TitleLaunchInfo?: {
        __typename?: 'M365Acquisition';
        appId?: string | null;
        titleId: string;
        acquisitionState: Types.AcquisitionState;
        acquisitionContext?: Types.AcquisitionContext | null;
        canBeUnacquired?: boolean | null;
        manifestId?: string | null;
        titleDefinition: {
            __typename?: 'Title';
            name: string;
            accentColor?: string | null;
            ingestionId: string;
            blockStatus?: boolean | null;
            ingestionSource?: Types.IngestionSource | null;
            categories?: Array<string> | null;
            developerName?: string | null;
            developerUrl?: string | null;
            isFullTrust?: boolean | null;
            shortDescription?: string | null;
            longDescription?: string | null;
            permissions?: Array<string> | null;
            showLoadingIndicator?: boolean | null;
            scope?: Types.TitleScope | null;
            cultureName?: string | null;
            termsOfUseUrl?: string | null;
            validDomains?: Array<string> | null;
            version?: string | null;
            manifestVersion?: string | null;
            elementDefinitions?: {
                __typename?: 'ElementDefinition';
                composeExtensions?: Array<{
                    __typename?: 'MessageExtension';
                    botId?: string | null;
                    canUpdateConfiguration?: boolean | null;
                    scopes?: Array<Types.AppScope | null> | null;
                    commandTypes?: Array<Types.InputExtensionCommandType | null> | null;
                    commands?: Array<{
                        __typename?: 'InputExtensionCommand';
                        context?: Array<Types.InputExtensionCommandContext | null> | null;
                        fetchTask?: boolean | null;
                        description?: string | null;
                        id?: string | null;
                        initialRun?: boolean | null;
                        title?: string | null;
                        type?: Types.InputExtensionCommandType | null;
                        parameters?: Array<{
                            __typename?: 'InputExtensionCommandParameter';
                            name?: string | null;
                            title?: string | null;
                            description?: string | null;
                        } | null> | null;
                    } | null> | null;
                    messageHandlers?: Array<{
                        __typename?: 'InputExtensionMessageHandler';
                        value?: {
                            __typename?: 'InputExtensionMessageHandlerValue';
                            domains?: Array<string | null> | null;
                        } | null;
                    } | null> | null;
                }> | null;
            } | null;
            iconSmall?: {
                __typename?: 'M365AcquisitionIcon';
                uri: string;
            } | null;
            iconLarge?: {
                __typename?: 'M365AcquisitionIcon';
                uri: string;
            } | null;
            iconOutline?: {
                __typename?: 'M365AcquisitionIcon';
                uri: string;
            } | null;
            authorization?: {
                __typename?: 'AppAuthorization';
                permissions?: {
                    __typename?: 'AppPermissions';
                    orgWide?: Array<{
                        __typename?: 'AppPermissionsNode';
                        name?: string | null;
                        type?: Types.AppPermissionsNodeType | null;
                    } | null> | null;
                    resourceSpecific?: Array<{
                        __typename?: 'AppPermissionsNode';
                        name?: string | null;
                        type?: Types.AppPermissionsNodeType | null;
                    } | null> | null;
                } | null;
            } | null;
            webApplicationInfo?: {
                __typename?: 'WebApplicationInfo';
                id?: string | null;
                resource?: string | null;
            } | null;
        };
    } | null;
};
export const ComposeExtensionFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ComposeExtensionFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageExtension' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'botId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'canUpdateConfiguration' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'scopes' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'commandTypes' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commands' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fetchTask' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'initialRun' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parameters' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'description' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messageHandlers' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'domains' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ComposeExtensionFragmentFragment, unknown>;
export const TitleDefinitionFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'titleDefinition' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Title' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'accentColor' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ingestionId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'blockStatus' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'ingestionSource' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'developerName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'developerUrl' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'elementDefinitions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'composeExtensions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ComposeExtensionFragment',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'isFullTrust' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'iconSmall' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uri' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'iconLarge' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uri' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'iconOutline' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'uri' } }],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'shortDescription' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'longDescription' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'showLoadingIndicator' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'cultureName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'termsOfUseUrl' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'authorization' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'permissions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'orgWide' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'type' },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'resourceSpecific' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'type' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'validDomains' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'manifestVersion' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webApplicationInfo' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'resource' } },
                            ],
                        },
                    },
                ],
            },
        },
        ...ComposeExtensionFragmentFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<TitleDefinitionFragment, unknown>;
export const TitleLaunchInfoDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'titleLaunchInfo' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'titleId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'mailboxInfo' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'MailboxInfoInput' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'm365TitleLaunchInfo' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'titleId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'titleId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'mailboxInfo' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'mailboxInfo' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'appId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'titleId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'acquisitionState' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'acquisitionContext' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'canBeUnacquired' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'manifestId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'titleDefinition' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'titleDefinition' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        ...TitleDefinitionFragmentDoc.definitions,
    ],
} as unknown as DocumentNode<TitleLaunchInfoQuery, TitleLaunchInfoQueryVariables>;
