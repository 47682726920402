import { isFeatureEnabled } from 'owa-feature-flags';
import { isOfflineSyncAvailable } from 'owa-offline-sync-feature-flags/lib/isOfflineSyncAvailable';

export function isPlacesOfflineSyncEnabled() {
    const shouldCheck = isFeatureEnabled('msplaces-offline-skip-policy-settings');

    // Currently, we are already using IndexedDB storage without the policy settings check,
    // so by default, we should return true to skip the check.
    if (!shouldCheck) {
        return true;
    }

    return isOfflineSyncAvailable();
}
