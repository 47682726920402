import type { MailboxInfo } from 'owa-client-types';
import { getOrgCalendarShowDirectReportsCloudSetting } from '../selectors/getOrgCalendarCloudSetting';

const ORG_CALENDAR_SHOW_DIRECT_REPORTS_DEFAULT = true;
/**
 * Gets the default value for the show direct reports organization calendar state
 * @param mailboxInfo the mailbox info
 * @returns the boolean value for the show direct reports organization calendar state
 */
export default function getOrgCalendarShowDirectReportsEnabled(mailboxInfo: MailboxInfo): boolean {
    const orgCalendarShowDirectReports = getOrgCalendarShowDirectReportsCloudSetting(mailboxInfo);
    return orgCalendarShowDirectReports != null
        ? orgCalendarShowDirectReports
        : ORG_CALENDAR_SHOW_DIRECT_REPORTS_DEFAULT;
}
