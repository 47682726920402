import { HybridspaceError } from 'hybridspace-common/lib/components/HybridspaceError';
import { placesFwkBootPtsCompleteAction } from 'hybridspace-performance-datapoints';
import { logAppBootStep } from 'hybridspace-telemetry/lib/logAppBootStep';
import { isRunningInMetaOSHub } from 'owa-config';
import {
    lazyInitializeMetaOsAppSdk,
    lazyNotifyAppLoaded,
    lazyNotifySuccess,
} from 'owa-metaos-app-bootstrap';
import { observer } from 'owa-mobx-react';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { usePlacesSettings } from 'places-settings';
import { setModuleStateSafe } from 'places-user-session-stores';
import React from 'react';
import AccessDenied from './AccessDenied';
import { HybridspaceLoadingScreen } from './HybridspaceLoadingScreen';
import {
    unableToLoadPlacesSettings,
    unableToLoadPlacesSettingsButtonLabel,
    unableToLoadPlacesSettingsTitle,
} from './PlacesPermissionValidation.locstring.json';

import type { PlacesSettings } from 'places-settings';

export default observer(function PlacesPermissionValidation({
    hostApp,
    children,
}: React.PropsWithChildren<{
    hostApp: string;
}>) {
    const mailboxInfo = getModuleContextMailboxInfo();
    const { loading, failed, noAccess, retry } = usePlacesSettings(
        mailboxInfo,
        true /* shouldUseMainThread */,
        onPlacesTenantSettingComplete
    );

    React.useEffect(() => {
        if (!loading) {
            // this is used to remove the custom loading screen once we completed places tenant settings
            setModuleStateSafe('complete');

            // it doesn't take places tenant setting success or failure into account as Places app would handle that user experience
            // we are only using this to let the host app know that we are in a good state to proceed, and to avoid timed out from hub.
            if (isRunningInMetaOSHub()) {
                lazyInitializeMetaOsAppSdk.importAndExecute().then(() => {
                    lazyNotifyAppLoaded.importAndExecute();
                    lazyNotifySuccess.importAndExecute();
                });
            }
        }
    }, [loading]);

    React.useEffect(() => {
        if (!loading) {
            logAppBootStep('AppBootEnd', { hasError: failed });
        }
    }, [loading, failed]);

    if (loading) {
        return <HybridspaceLoadingScreen />;
    }

    if (failed) {
        return (
            <HybridspaceError
                retry={retry}
                buttonLabel={unableToLoadPlacesSettingsButtonLabel}
                errorTitle={unableToLoadPlacesSettingsTitle}
                errorMessage={unableToLoadPlacesSettings}
                brandedButtonStyle={true}
                dataTelemetryId={'PlacesPermissionValidation'}
            />
        );
    }

    if (noAccess) {
        return <AccessDenied showSignOut={!hostApp} />;
    }

    return children;
}, 'PlacesPermissionValidation');

function onPlacesTenantSettingComplete(placesSettings: PlacesSettings) {
    placesFwkBootPtsCompleteAction(
        placesSettings.placesWebAppEnabled,
        placesSettings.placesPremiumEnabled,
        placesSettings.reason,
        placesSettings.shouldUsePlacesLicensing
    );
}
