import { createLazyComponent, LazyModule, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PlacesExplore" */ './lazyIndex'),
    { name: 'PlacesExplore' }
);

export const PlacesBuildingPage = createLazyComponent(lazyModule, m => m.PlacesBuildingPage);

export const lazyPlacesBuildingModule = new LazyImport(lazyModule, m => m);
