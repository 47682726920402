import { GetRoomPlacesDocument } from 'hybridspace-graphql/lib/graphql/__generated__/getRoomsPlaceQuery.interface';
import { queryWithError } from 'hybridspace-graphql/lib/utils/queryWithError';
import type { Maybe, Place } from 'owa-graph-schema';
import type { RoomLocation } from '../types/roomLocation';
import { setBuildingForRoomMutator } from '../mutators';
import { getBuildingForRoom } from '../store';

export default async function fetchRoomLocationsByEmail(
    roomEmailAddresses: string[]
): Promise<RoomLocation[]> {
    if (roomEmailAddresses.length == 0) {
        return [];
    }

    const locationsFromCache: RoomLocation[] = [];
    const roomsToQuery: string[] = [];
    roomEmailAddresses.forEach(email => {
        const location = getBuildingForRoom(email);
        if (location) {
            locationsFromCache.push(location);
        } else {
            roomsToQuery.push(`MailboxDetails.EmailAddress = "${email}"`);
        }
    });

    const query = `Type = "Room" AND (${roomsToQuery.join(' OR ')})`;
    const { data } = await queryWithError(GetRoomPlacesDocument, {
        variables: { placesRequest: { query, includeAncestors: true } },
        fetchPolicy: 'cache-first',
    });

    const buildingRooms = (data?.places?.places || []) as Place[];
    const retrievedLocations = mapRoomsToBuilding(buildingRooms);

    return [...locationsFromCache, ...retrievedLocations];
}

function mapRoomsToBuilding(places: Place[]): RoomLocation[] {
    const roomLocations: RoomLocation[] = [];
    const idToPlace = new Map(places.map(p => [p.id, p]));

    places.forEach(place => {
        if (place.type === 'Room' && place.parentId) {
            const building = findBuilding(place.parentId, idToPlace);
            const emailAddress = place.mailboxDetails?.emailAddress;
            if (building && emailAddress) {
                const location = { room: place, building };
                roomLocations.push(location);
                setBuildingForRoomMutator(emailAddress, location);
            }
        }
    });

    return roomLocations;
}

function findBuilding(
    placeId: Maybe<string> | undefined,
    idToPlace: Map<string, Place>
): Place | undefined {
    if (!placeId) {
        return undefined;
    }
    const place = idToPlace.get(placeId);
    if (!place) {
        return undefined;
    }
    if (place.type === 'Building') {
        return place;
    }
    return findBuilding(place.parentId, idToPlace);
}
