import { createBrowserHistory } from '@remix-run/router';

import type { History, To, Path } from '@remix-run/router';

const PARAMETERS_TO_PRESERVE: Record<string, number> = {
    gulp: 1,
    branch: 1,
    useBranchCdn: 1,
    features: 1,
    layout: 1,
    devtools: 1,
    culture: 1,
    version: 1,
    useIndex: 1,
    enableTracing: 1,
    enforceCsp: 1,
    cspoff: 1,
    owsserver: 1,
    bpm: 1,
    debugJs: 1,
    lightning: 1,
    debugRtl: 1,
    pm: 1,
    ep: 1, // entrypoint
    utm_source: 1, // marketing
    dp: 1,
    utdp: 1,
    autoLogActions: 1,
    track: 1,
    anonymous: 1,
    // gulp ORI
    esm: 1,
    preSrc: 1,
    ori: 1,
    // boot flights
    appendbootflights: 1,
    removebootflights: 1,
    // augloop local development
    localAL: 1,
    openSettingsDialog: 1, // places settings dialog
    MidgardBranch: 1, // to test different LPC branches with Places
    // Places app testings
    epageState: 1, // to test different states of the explore page
    epageError: 1, // to test different error states of the explore page
    ecomponentState: 1, // to test different states of the explore components
    devSubPageId: 1, // boot metaos redirection test
};

function preserveQueryParameters(history: History, to: To): Partial<Path> {
    const newSearchParams: Record<string, string> = {};

    for (const [key, value] of new URLSearchParams(history.location.search)) {
        if (PARAMETERS_TO_PRESERVE[key] === 1) {
            newSearchParams[key] = value;
        }
    }

    const path = stringToPath(to);
    for (const [key, value] of new URLSearchParams(path.search)) {
        newSearchParams[key] = value;
    }

    path.search = `?${new URLSearchParams(newSearchParams).toString()}`;
    return path;
}

function stringToPath(to: To): Partial<Path> {
    if (typeof to == 'string') {
        const url = new URL(to, window.location.href);
        return {
            pathname: url.pathname,
            search: url.search,
            hash: url.hash,
        };
    }
    return to;
}

const history: History = createBrowserHistory({ window });

const originalPush = history.push;
/* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
 * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
 *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
history.push = (to: To, state?: any) => {
    originalPush.call(history, preserveQueryParameters(history, to), state);
};

const originalReplace = history.replace;
/* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
 * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
 *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
history.replace = (to: To, state?: any) => {
    originalReplace.call(history, preserveQueryParameters(history, to), state);
};

export default history;
