export enum WorkLocationSurfaceType {
    Office = 'Office',
    Remote = 'Remote',
    Unspecified = 'Unspecified',
    Hybrid = 'Hybrid',
    TimeOff = 'TimeOff',
}

export enum DailyPeekSuggestionIntensity {
    None = 0,
    Informational = 1,
    Recommended = 2,
    Strong = 3,
    Copilot = 4,
}

export enum WorkLocationControlType {
    None,
    ReadOnly,
    ReadWrite,
    PlacesReadWrite /** Work location type for Places callout, behind flight: cal-surface-places-callout*/,
}

export enum WorkPlansDailyPeekSource {
    Surface = 'Surface',
    MyDayPane = 'MyDayPane',
    Places = 'Places',
    OPX = 'OPX',
}
