//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import type * as Types from 'owa-graph-schema';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetCollaboratorsQueryVariables = Types.Exact<{
    input: Types.ListCollaboratorsRequest;
}>;
export type GetCollaboratorsQuery = {
    __typename?: 'Query';
    listCollaborators?: {
        __typename?: 'ListCollaboratorsResponse';
        collaborators?: Array<{
            __typename?: 'Collaborator';
            mailboxId?: string | null;
            isFavorite?: boolean | null;
            suggestedReasons?: Array<Types.SuggestedReason | null> | null;
            source?: Types.Source | null;
            primarySmtpAddress?: string | null;
            sortKey?: string | null;
            id?: string | null;
        } | null> | null;
    } | null;
};
export const GetCollaboratorsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetCollaborators' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ListCollaboratorsRequest' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listCollaborators' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'request' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'collaborators' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'id' },
                                                name: { kind: 'Name', value: 'mailboxId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'mailboxId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isFavorite' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'suggestedReasons' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'source' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'primarySmtpAddress' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'sortKey' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetCollaboratorsQuery, GetCollaboratorsQueryVariables>;
